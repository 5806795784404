import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery';

import FormInput from '../components/form/FormInput';
import MessageInfoBox from '../components/other/MessageInfoBox';
import PageBanner from '../components/banner/PageBanner';
import PageHelmet from '../components/helmet/PageHelmet';

import globalVariable from '../global';
import captchaImg from '../../src/img/captcha.jpg';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation, Trans } from 'react-i18next';
import { get } from 'sortablejs';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactPage = () => {

    const dispatch = useDispatch()
    const queries = useMediaQuery('(max-width: 1279px)');
    const userInfo = useSelector((state) => state.userInfo);
    const userEmails = userInfo.userInfo ? userInfo.userInfo.company.acCompanyMail1.split(';') : '';
    const [captcha, setCaptcha] = useState('I');
    const [honeyPot, setHoneyPot] = useState(0);
    const [pageSeoData, setPageSeoData] = useState({});
    const [contactParagraphs, setContactParagraphs] = useState([]);
    const [contactDetails, setContactDetails] = useState([]);
    const { t } = useTranslation('contactPage');
    const getB2bSettings = useSelector((state) => state.getB2bSettings);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [recaptchaError, setRecaptchaError] = useState(false);
    const [language, setLanguage] = useState(t('recaptchaLang'));
    // console.log(getB2bSettings, "getB2bSettings");

    const characters = 'qwertyuopasdfghjkzxcvbnm1234567890';
    // var captcha = '';
    useEffect(() => {
        dispatch(getPageSeo(378)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        generateString(6) // Function called here and save in captcha variable
        dispatch(globalStatistic(378, 'PAGE'));
        // console.log(getB2bSettings, "getB2bSettings");
        // setContactParagraphs(getB2bSettings.acGeneralContactInfo.split(';').map(paragraph =>
        //     paragraph.split(',').map((line, index) =>
        //         <React.Fragment key={index}>
        //             {line.trim()}
        //             {index < paragraph.split(',').length - 1 && <br />}
        //         </React.Fragment>
        //     )
        // ));
    }, [])
    useEffect(() => {
        setContactParagraphs(formatContactInfo(getB2bSettings.items.acGeneralContactInfo));
        setContactDetails(getB2bSettings.items.contactDetail);
    }, [getB2bSettings])

    function generateString(length) {
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        setCaptcha(result);
        //  return result;
    }



    const [values, setValues] = useState({
        emailContact: userEmails[0],
        nameContact: "",
        textContact: ""
    });

    const [errors, setErrors] = useState({
        emailContact: 1,
        nameContact: 1,
        textContact: 1
    });

    const [validInput, setValidInput] = useState({
        emailContact: 0,
        nameContact: 0,
        textContact: 0
    })

    const onChangeFunction = (e) => {
        if (e.target.name == 'contact_us_via_pigeon') {
            if (e.target.value != '') {
                setHoneyPot(1)
            } else {
                setHoneyPot(0)
            }
        }
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const setErrorsFunction = (name, val) => {
        setErrors({ ...errors, [name]: val });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.group('Starting form validation');
        // console.log('Values:', values);
        if (!captchaValue) {
            // console.log('reCAPTCHA not completed');
            // console.groupEnd();
            toast(<MessageInfoBox message='errorCaptcha' type={0} />, {
                className: 'red-background',
            });
            setRecaptchaError(true); // Show red border if reCAPTCHA is not completed
            return;
        }
        // console.log(captcha)
        // console.log(captchaInput.captchaValue, 'captchaInput.captchaValue')
        // honeypot provera
        if (honeyPot == 0) {
            // console.log('Honeypot passed');
            setValidInput({
                ...validInput,
                emailContact: values.emailContact == '' && 1,
                nameContact: values.nameContact == '' && 1,
                textContact: values.textContact == '' && 1
            });

            console.log(errors, "errors");

            if (errors.emailContact == 0 && errors.nameContact == 0 && errors.textContact == 0) {
                // console.log('Form is valid');
                //slanje email-a
                axios({
                    method: "POST",
                    url: "https://newb2b.ewe.rs:5004/contact",
                    data: {
                        values: values,
                    }
                }).then((response) => {
                    // console.log('Response:', response);

                    if (response.data.isOk == 1) {

                        toast(<MessageInfoBox message={response.data.acMsg} type={response.data.isOk} />, {
                            className: response.data.isOk == 1 ? 'green-background' : 'red-background',
                        });

                        //resetovanje inputa i provera obaveznih polja
                        setValidInput({
                            emailContact: 0,
                            nameContact: 0,
                            textContact: 0
                        });

                        setErrors({
                            emailContact: 1,
                            nameContact: 1,
                            textContact: 1
                        })

                        setValues({
                            emailContact: "",
                            nameContact: "",
                            textContact: ""
                        });

                    }
                });

            } else {
                // console.log('Form is not valid');
                // console.groupEnd();
                toast(<MessageInfoBox message='errorReqiuredFormInputs' type={0} />, {
                    className: 'red-background',
                });
            }
        } else {
            // console.log('Honeypot failed');
            // console.groupEnd();
            toast(<MessageInfoBox message='somethingWentWrong' type={0} />, {
                className: 'red-background',
            });
        }
        // console.log('Ending form validation');
    };

    const formatContactInfo = (data) => {
        if (!data) return [];

        return data.split(';').map(paragraph =>
            paragraph.split(',').map((line, index) =>
                <React.Fragment key={index}>
                    {line.trim()}
                    {index < paragraph.split(',').length - 1 && <br />}
                </React.Fragment>
            )
        );
    };
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        setRecaptchaError(false); // Reset error when reCAPTCHA is confirmed
    };
    // console.log(contactParagraphs, "contactParagraphs");

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="website"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-catalog-content contact-page-content">
                <div className="container-fluid-custom">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="banner-image-on-top-page">
                                <PageBanner categoryType="" categoryId="" pageId="378" />
                            </div>
                            <div className="stats-order-content-holder">
                                <h1>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('naslov')}</h1>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            </div>
                            {/* <div className="contact-title">
                                <h1>Kontakt forma</h1>
                            </div> */}
                            {/* <div className="contact-desc">Poštovani partneri, u saradnji sa servisom Nepo System doo obezbedili smo besplatnu PickUp&Return uslugu za sve uređaje kupljne kod nas. Molimo Vas popunite formu ispod kako biste zakazali preuzimanje uređaja od strane kurirske službe. Kako bi smo najbrže rešili Vaš reklamaciju molimo Vas da nam pomognete unošenjem detalja uređaja koji šaljete.</div> */}


                            <div className="contact-form22">
                                {/* <div className="contact-form-title">Kontaktirajte nas</div> */}
                                <div className="contact-form-body">
                                    <div className="form-body-holder-contact">
                                        <div className="form-contact-holder">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h4 className="contact-form-col-title">{t('naslov2')}</h4>
                                                    {/* <p><Trans i18nKey="contactPage:kontaktPodaci" components={{ br: <br /> }} /></p>
                                                    <p><Trans i18nKey="contactPage:ostaleInformacije" components={{ br: <br /> }} /></p>
                                                    <p><Trans i18nKey="contactPage:brojeviRacuna" components={{ br: <br /> }} /></p> */}
                                                    {contactParagraphs && contactParagraphs.length > 0 && contactParagraphs.map((paragraph, index) =>
                                                        <p key={index}>
                                                            {paragraph}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="col-sm-6">
                                                    <form name="contactFormLogout" className="contactFormLogout">
                                                        <div className="form-group">
                                                            <FormInput
                                                                type="text"
                                                                name="nameContact"
                                                                placeholder={t('placeholder1')}
                                                                isRequired="1"
                                                                nameclassName="contactName"
                                                                messageError={t('porukaGreske')}
                                                                label={t('inputLabel1')}
                                                                value={values['nameContact']}
                                                                onChange={(e) => onChangeFunction(e)}
                                                                validInputValue={validInput.nameContact}
                                                                setErrorsFunc={setErrorsFunction}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <FormInput
                                                                type="email"
                                                                name="emailContact"
                                                                placeholder={t('placeholder2')}
                                                                isRequired="1"
                                                                nameclassName="contactEmail"
                                                                messageError={t('porukaGreske2')}
                                                                label={t('inputLabel2')}
                                                                value={values['emailContact']}
                                                                onChange={(e) => onChangeFunction(e)}
                                                                validInputValue={validInput.emailContact}
                                                                setErrorsFunc={setErrorsFunction}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <FormInput
                                                                type="textarea"
                                                                name="textContact"
                                                                isRequired="1"
                                                                nameclassName="contactDesc"
                                                                placeholder={t('placeholder3')}
                                                                messageError={t('porukaGreske')}
                                                                label={t('inputLabel3')}
                                                                value={values['textContact']}
                                                                onChange={(e) => onChangeFunction(e)}
                                                                validInputValue={validInput.textContact}
                                                                setErrorsFunc={setErrorsFunction}
                                                            />
                                                        </div>
                                                        <div className={`re-captcha-container-contact ${recaptchaError ? 'recaptcha-error' : ''}`}>
                                                            <ReCAPTCHA
                                                                sitekey="6LchZCAoAAAAAOM85rAfDy6bLZ9JBIE9gEok9Kyq"
                                                                onChange={handleCaptchaChange}
                                                                hl={language}
                                                            />
                                                        </div>
                                                        {/* HONEY POT POLJE */}
                                                        <input type="text" name="contact_us_via_pigeon" value="" style={{ display: "none" }} autoComplete="off" onChange={(e) => onChangeFunction(e)} />
                                                        <div className="button-add-holder-article">
                                                            <button type="submit" className="btn btn-info btn-add-article-pickupreturn" onClick={handleSubmit}>{t('posalji')}</button>
                                                        </div>

                                                    </form>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="contact-map">
                                                        <iframe title='googleMap' src={t('mapa')} style={{ border: "0" }} allowFullScreen={""} loading={"lazy"} referrerpolicy={"no-referrer-when-downgrade"}></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            {contactDetails && contactDetails.length > 0 && contactDetails.map((contact, index) => (
                                                <div key={index} className={queries ? "col-sm-6" : "col-lg-4"}>
                                                    <div className="contact-item">
                                                        <div className="row contact-info">
                                                            <div className="col-4">
                                                                <img className="img-fluid" alt={`Ikonica ${contact.acDepartment}`} src={contact.acIcon} />
                                                            </div>
                                                            <div className="col-8">
                                                                <div className="contact-item-text">
                                                                    <h4>{contact.acDepartment}</h4>
                                                                    {contact.acTel}<br />
                                                                    <a href={`mailto:${contact.acEmail}`} target="_self">{contact.acEmail}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {/* <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica prodaje' src={`${globalVariable.domainName}/media/contactPage/prodaja.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov4')}</h4>
                                                                {t('telefon1')}<br />
                                                                <a href={`mailto:${t('mail2')}`} target="_self">{t('mail2')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica briga o klijentima' src={`${globalVariable.domainName}/media/contactPage/briga-o-klijentima.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov5')}</h4>
                                                                {t('telefon2')}<br />
                                                                <a href={`mailto:${t('mail3')}`} target="_self">{t('mail3')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica poslovnih resenja' src={`${globalVariable.domainName}/media/contactPage/poslovna-resenja.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov6')}</h4>
                                                                {t('telefon3')}<br />
                                                                <a href={`mailto:${t('mail4')}`} target="_self">{t('mail4')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica marketinga' src={`${globalVariable.domainName}/media/contactPage/marketing.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov7')}</h4>
                                                                {t('telefon1')}<br />
                                                                <a href={`mailto:${t('mail5')}`} target="_self">{t('mail5')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica reklamacija' src={`${globalVariable.domainName}/media/contactPage/reklamacije.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov8')}</h4>
                                                                {t('telefon4')}<br />
                                                                <a href={`mailto:${t('mail6')}`} target="_self">{t('mail6')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica rasporeda transporta' src={`${globalVariable.domainName}/media/contactPage/raspored-transporta.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov9')}</h4>
                                                                {t('telefon1')}<br />
                                                                <a href={`mailto:${t('mail7')}`} target="_self">{t('mail7')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica servisa' src={`${globalVariable.domainName}/media/contactPage/servis.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4><Trans i18nKey="contactPage:naslov10" components={{ br: <br /> }} /></h4>
                                                                {t('telefon1')}<br />
                                                                <a href={`mailto:${t('mail8')}`} target="_self">{t('mail8')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={queries ? "col-sm-6" : "col-lg-4"}>
                                                <div className="contact-item">
                                                    <div className="row contact-info">
                                                        <div className="col-4">
                                                            <img className="img-fluid" alt='Ikonica racunovodstva' src={`${globalVariable.domainName}/media/contactPage/racunovodstvo.png`} />
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="contact-item-text">
                                                                <h4>{t('naslov11')}</h4>
                                                                {t('telefon1')}<br />
                                                                <a href={`mailto:${t('mail9')}`} target="_self">{t('mail9')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default ContactPage;