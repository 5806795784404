import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { axiosinstance } from '../../configAxios';

import { getCompanyPromoMaterial } from '../../redux/actions/companyPromoMaterialActions';

import MessageInfoBox from '../other/MessageInfoBox';
import { useTranslation } from 'react-i18next';

const PollAnswerContent = ({ item, setShow }) => {

    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);
    const [showNext, setShowNext] = useState(item ? item.surveyQuestionList[0].acAnswerType : null);
    const getUserInfo = useSelector((state) => state.userInfo.userInfo.company);
    const { t } = useTranslation('other');

    // const storageValues = localStorage.getItem('userSurveyValues') ? JSON.parse(localStorage.getItem('userSurveyValues')) : {
    //     radioType: "",
    //     checkboxType: [],
    //     inputType: "",
    //     textType: "",
    //     radioImageType: "",
    //     checkboxImageType: []
    // };


    const question = item.surveyQuestionList.filter((q, k) => k === activeIndex);
    const answers = question[0].surveyAnswerList.filter((a) => a.acQuestionKey === question[0]._id);
    let storageKey = question[0]._id;
    // const storageValues = localStorage.getItem('userSurveyValues') ? JSON.parse(localStorage.getItem('userSurveyValues')) : { [storageKey]: "" };

    const [value, setValue] = useState("");
    const [arrayValue, setArrayValue] = useState([]);
    const [values, setValues] = useState({ [storageKey]: "" })
    const [error, setError] = useState(true);
    const [validInput, setValidInput] = useState(0);

    let answerKey = "";
    if (question[0].acAnswerType == "checkboxType" || question[0].acAnswerType == "checkboxImageType") {
        let arrayAnswerKey = [];
        answers.filter(item => {
            if (arrayValue.includes(item.acAnswer)) {
                arrayAnswerKey.push(item._id)
            }
        })
        answerKey = arrayAnswerKey.toString()
    } else {
        if (answers[0].acAnswer != "") {
            answers.map(item => {
                if (item.acAnswer === value) {
                    answerKey = item._id
                }
            })
        } else {
            answerKey = answers[0]._id
        }
    }

    let showEnd = false;
    let i = activeIndex;
    // if (storageValues[storageKey] != "") {
    //     if (i != item.surveyQuestionList.length - 1) {
    //         i++
    //         setActiveIndex(i);
    //         console.log(activeIndex, "ai")
    //         // setShowNext(item.surveyQuestionList[i].acAnswerType)
    //     } else {
    //         showEnd = true;
    //     }
    // }

    if (question[0].isAnswered == 1) {
        if (i != item.surveyQuestionList.length - 1) {
            i++
            setActiveIndex(i);
            setShowNext(item.surveyQuestionList[i].acAnswerType)
        } else {
            showEnd = true;
        }
    }

    const onClickShowNext = () => {
        if (!error) {
            if (i == item.surveyQuestionList.length - 1) {
                setShow()
            } else {
                i++;
            }
            setActiveIndex(i);
            setShowNext(item.surveyQuestionList[i].acAnswerType)
            try {
                const attributes = {};
                attributes['acSurveyKey'] = question[0].acSurveyKey;
                attributes['acQuestionKey'] = question[0]._id;
                attributes['acAnswerKey'] = answerKey;
                attributes['anUserKey'] = getUserInfo.department[0].user[0].anUserKey;
                attributes['anCompanyKey'] = getUserInfo.anCompanyKey;
                attributes['acAnswer'] = value ? value : arrayValue.toString();

                const configRes = axiosinstance(attributes, '8224', 'postSurveyAnswer');

                axios(configRes)
                    .then(function (response) {
                        if (response.data.response.acInfo !== "") {
                            axios({
                                method: "POST",
                                url: "https://newb2b.ewe.rs:5004/survey",
                                data: {
                                    promoCode: response.data.response.acInfo,
                                    userEmail: getUserInfo.department[0].user[0].acEmail
                                }
                            }).then(function (response) {
                                toast(<MessageInfoBox message={response.data.acMsg} type={response.data.isOk} />, {
                                    className: response.data.isOk == 1 ? 'green-background' : 'red-background',
                                });
                            }).catch(function (error) {
                                console.log(error);
                                toast(<MessageInfoBox message="globalError" type="0" />, {
                                    className: 'red-background',
                                });
                            })
                        } else {
                            toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                                className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                            });
                        }
                        // localStorage.setItem('userSurveyValues', JSON.stringify(values));
                        dispatch(getCompanyPromoMaterial());
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast(<MessageInfoBox message="globalError" type="0" />, {
                            className: 'red-background',
                        });
                    });

            } catch (error) {
                console.log(error)
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            }
            setValue("")
            setArrayValue([])
            setError(true)
        } else {
            setValidInput(1)
        }

    }

    const onChangeFunction = (e) => {
        if (e.target.name == "checkboxType" || e.target.name == "checkboxImageType") {
            setArrayValue([...arrayValue, e.target.value]);
            setValues({ ...values, [storageKey]: [...values[storageKey], e.target.value] });
        } else {
            setValue(e.target.value);
            setValues({ ...values, [storageKey]: e.target.value });
        }
        setError(false)
        setValidInput(2)
    }

    return (
        showEnd ?
            <>
                <div className="poll-thank-you-note text-center">
                    <h1>{t('hvalaNaVremenu')}</h1>
                </div>
            </> :
            <>
                <div className="survey-info-time">
                    <div><i className="far fa-clock"></i> {item.anResponseTime} min</div>
                    <div>{activeIndex + 1} / {item.surveyQuestionList.length}</div>
                </div>
                <div className="poll-question-answer-block">
                    <div className="poll-question-title text-center">
                        <h2>{question[0].acQuestion}</h2>
                    </div>
                    < div className="poll-quest-answer-block-range">
                        {showNext == "radioType" &&
                            <>
                                {question[0].surveyAnswerList.map((answer, key) => {
                                    return <div className="radio-option" key={key}>
                                        <label className={`btn button-answer-item ${values[storageKey] == answer.acAnswer ? "button-checked" : ""}`}>
                                            <input type='radio' name="radioType" style={{ display: "none" }} value={answer.acAnswer} onClick={(e) => onChangeFunction(e)} />{answer.acAnswer}
                                        </label>
                                    </div>
                                })}
                            </>
                        }
                        {showNext == "checkboxType" &&
                            <>
                                {question[0].surveyAnswerList.map((answer, key) => {
                                    return <div className="check-box-option" key={key}>
                                        <input type='checkbox' className="form-check-input" name="checkboxType" value={answer.acAnswer} onChange={(e) => onChangeFunction(e)} /> {answer.acAnswer}
                                    </div>
                                })}
                            </>
                        }
                        {showNext == "inputType" &&
                            <input type='text' name="inputType" className="form-control" placeholder='Unesite odgovor...' value={value} onChange={(e) => onChangeFunction(e)} />
                        }
                        {showNext == "textType" &&
                            <textarea placeholder="Tekst..." name="textType" className="form-control" value={value} onChange={(e) => onChangeFunction(e)} ></textarea>
                        }
                        {showNext == "radioImageType" &&
                            <>
                                {question[0].surveyAnswerList.map((answer, key) => {
                                    return <div className="radio-option" key={key}>
                                        <img src={answer.acAnswer} className="img-fluid" alt="answer option" /> <input type='radio' className="form-check-input radio-option-answer" name="radioImageType" value={answer.acAnswer} onChange={(e) => onChangeFunction(e)} />
                                    </div>
                                })}
                            </>
                        }
                        {showNext == "checkboxImageType" &&
                            <>
                                {question[0].surveyAnswerList.map((answer, key) => {
                                    return <div className="radio-option" key={key}>
                                        <img src={answer.acAnswer} className="img-fluid" alt="answer option" /> <input type='checkbox' className="form-check-input radio-option-answer" name="checkboxImageType" value={answer.acAnswer} onChange={(e) => onChangeFunction(e)} />
                                    </div>
                                })}
                            </>
                        }
                    </div>
                    {validInput == 1 &&
                        <div className="survey-invalid text-center">
                            <span>{t('obaveznoPolje')}</span>
                        </div>
                    }
                </div>
                <div className="poll-question-answer-block-button">
                    <button className="btn" onClick={onClickShowNext}>{t('potvrdite')}</button>
                </div>

            </>
    )
}

export default PollAnswerContent