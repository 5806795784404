import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';

import { editCompanyMailFunction, editCompanyFunction } from '../../redux/actions/userActions';

import MessageInfoBox from '../other/MessageInfoBox';
import { useTranslation } from 'react-i18next';
import SecureLS from 'secure-ls';

const CompanyProfile = ({ getUserInfo, user }) => {

    const dispatch = useDispatch();
    let ls = new SecureLS({ encodingType: 'aes' });
    const userSettings = ls.get('userInfo');

    const [proFactureType, setProFactureType] = useState(getUserInfo.email.filter(i => i.acType == "proFactureJSON").length > 0 && getUserInfo.email.filter(i => i.acType == "proFactureJSON")[0].acEmail != "" ? "proFactureJSON" : "proFactureXML");
    const [removeProFacture, setRemoveProFacture] = useState({
        "acEmail": "",
        "acType": "proFactureJSON"
    });

    const [isLogo, setIsLogo] = useState(false)
    const [isHeader, setIsHeader] = useState(false)
    const [isFooter, setIsFooter] = useState(false)

    const [logoImg, setLogoImg] = useState(null)
    const [headerImg, setHeaderImg] = useState(null)
    const [footerImg, setFooterImg] = useState(null)
    const { t } = useTranslation('profile');

    const [values, setValues] = useState({
        facture: getUserInfo.email.filter(i => i.acType == "facture").length > 0 ? getUserInfo.email.filter(i => i.acType == "facture")[0].acEmail : "",
        endUser: getUserInfo.email.filter(i => i.acType == "endUser").length > 0 ? getUserInfo.email.filter(i => i.acType == "endUser")[0].acEmail : "",
        newsletter: getUserInfo.email.filter(i => i.acType == "newsletter").length > 0 ? getUserInfo.email.filter(i => i.acType == "newsletter")[0].acEmail : "",
        cart: getUserInfo.email.filter(i => i.acType == "cart").length > 0 ? getUserInfo.email.filter(i => i.acType == "cart")[0].acEmail : "",
        proFacture: getUserInfo.email.filter(i => i.acType == "proFactureXML" || i.acType == "proFactureJSON").length > 0 && getUserInfo.email.filter(i => i.acType == "proFactureXML" || i.acType == "proFactureJSON")[0].acEmail != "" ? getUserInfo.email.filter(i => i.acType == "proFactureXML" || i.acType == "proFactureJSON")[0].acEmail : getUserInfo.email.filter(i => i.acType == "proFacture").length > 0 ? getUserInfo.email.filter(i => i.acType == "proFacture")[0].acEmail : "",
        catalogXml: getUserInfo.email.filter(i => i.acType == "catalogXml").length > 0 ? getUserInfo.email.filter(i => i.acType == "catalogXml")[0].acEmail : "",
        acCompanyLogo: userSettings.company.acCompanyLogo,
        acCompanyMemoHeader: userSettings.company.acCompanyMemoHeader,
        acCompanyMemoFooter: userSettings.company.acCompanyMemoFooter,
    })

    const [value, setValue] = useState({
        "acEmail": "",
        "acType": ""
    });

    useEffect(() => {
        if (values.acCompanyLogo.trim() != '') {
            setIsLogo(true);
        } else {
            setIsLogo(false);
        }
        if (values.acCompanyMemoHeader.trim() != '') {
            setIsHeader(true);
        } else {
            setIsHeader(false);
        }
        if (values.acCompanyMemoFooter.trim() != '') {
            setIsFooter(true);
        } else {
            setIsFooter(false);
        }
    }, [values])

    useEffect(() => {
        if (values.acCompanyLogo.trim() != '') {
            setIsLogo(true);
        }
        if (values.acCompanyMemoHeader.trim() != '') {
            setIsHeader(true);
        }
        if (values.acCompanyMemoFooter.trim() != '') {
            setIsFooter(true);
        }
    }, [])

    const saveImg = (e, formData, file) => {
        const uploadPath = "profile";

        axios.post('https://newb2b.ewe.rs:5004/image-upload/' + uploadPath + '', formData)
            .then(res => {
                if (res.data.isOk == 0) {
                    toast(<MessageInfoBox message={res.data.msg} type={res.data.isOk} />, {
                        className: res.data.isOk == 1 ? 'green-background' : 'red-background',
                    });
                    // console.log(formData)
                } else {
                    //setValues({ ...values, [e]: `/media/${uploadPath}/` + res.data.fileName })
                    dispatch(editCompanyFunction({ ...values, [e]: `/media/${uploadPath}/` + res.data.fileName })).then(
                        setValues({ ...values, [e]: `/media/${uploadPath}/` + res.data.fileName })
                    )

                    if (e == "acCompanyLogo") {
                        setLogoImg(file);
                    } else if (e == "acCompanyMemoHeader") {
                        setHeaderImg(file)
                    } else {
                        setFooterImg(file)
                    }

                }
            })
    }

    const deleteImg = (targetName) => {

        // getting nested value based on targetName
        let objectPath = [targetName];
        let imagePath = objectPath.reduce((p, c) => p && p[c] || null, values)

        // splitting to extract type and name of file (assuming that those are last two parameters)
        let splittedPaths = imagePath.split('/')
        let fileName = splittedPaths[splittedPaths.length - 1]
        let type = splittedPaths[splittedPaths.length - 2]

        axios.post('https://newb2b.ewe.rs:5000/image-delete/' + type + '/' + fileName)
            .then(res => {
                if (res.data.isOk == 1) {
                    setValues({ ...values, [targetName]: ' ' })
                    if (targetName == "acCompanyLogo") {
                        setLogoImg(null);
                    } else if (targetName == "acCompanyMemoHeader") {
                        setHeaderImg(null)
                    } else {
                        setFooterImg(null)
                    }
                } else {
                    toast(<MessageInfoBox message={res.data.msg} type={res.data.isOk} />, {
                        className: res.data.isOk == 1 ? 'green-background' : 'red-background',
                    });
                }
            })
    }

    const handleFileInput = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        const formData = new FormData();
        formData.append('document', e.target.files[0]);

        saveImg(e.target.name, formData, e.target.files[0]);
    }

    const resetFileValue = (e) => {
        e.target.value = null
    }

    const removeImage = (e) => {
        // console.log(e.target.dataset.delete, 'e.target.dataset.delete')
        setValues({ ...values, [e.target.dataset.delete]: ' ' })
        dispatch(editCompanyFunction({ ...values, [e.target.dataset.delete]: ' ' }))
        deleteImg(e.target.dataset.delete)
    }

    //u slucaju da je potreban delete za slike//

    // const deleteImg = (e) => {
    //     if (values.hasOwnProperty(e.target.id)) {
    //         if (values[e.target.id] != "") {
    //             let path = values[e.target.id].split("/")
    //             axios.delete('http://localhost:5000/image-delete/' + path[4] + "/" + path[5] + '')
    //                 .then(res => {
    //                     toast(<MessageInfoBox message={res.data.msg} type={res.data.isOk} />, {
    //                         className: res.data.isOk == 1 ? 'green-background' : 'red-background',
    //                     });
    //                     if (res.data.isOk == 1) {
    //                         setValues({ ...values, [e.target.id]: "" })
    //                     }
    //                 })
    //         }
    //     }
    // }

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
        if (e.target.name == "proFacture") {
            setValue({
                "acEmail": e.target.value,
                "acType": proFactureType
            });
        } else {
            setValue({
                "acEmail": e.target.value,
                "acType": e.target.name
            });
        }
    }

    const onChangeSelect = (e) => {
        setProFactureType(e.target.value)

        if (e.target.value == "proFactureXML") {
            setRemoveProFacture({ "acEmail": "", "acType": "proFactureJSON" })
        } else if (e.target.value == "proFactureJSON") {
            setRemoveProFacture({ "acEmail": "", "acType": "proFactureXML" })
        }

        setValue({
            "acEmail": values.proFacture,
            "acType": e.target.value
        });
    }

    const handleInput = (e) => {
        e.preventDefault()

        if (value.acEmail != "" && value.acType != "") {
            if (value.acType == "proFactureXML" || value.acType == "proFactureJSON") {
                dispatch(editCompanyMailFunction(value));
                setTimeout(() => {
                    dispatch(editCompanyMailFunction(removeProFacture));
                }, 1000)
                setValue({
                    "acEmail": "",
                    "acType": ""
                })
            } else if (value.acType == "catalogXml") {
                axios({
                    method: "POST",
                    url: "https://newb2b.ewe.rs:5000/catalog-xml",
                    data: {
                        email: value.acEmail,
                        company: getUserInfo.acCompany,
                    }
                }).then(res => {
                    if (res.data.isOk == 1) {
                        dispatch(editCompanyMailFunction(value));
                        setValue({
                            "acEmail": "",
                            "acType": ""
                        })
                    } else {
                        toast(<MessageInfoBox message="globalError" type="0" />, {
                            className: 'red-background',
                        });
                    }
                }).catch(function (error) {
                    console.log(error, 'error');
                    toast(<MessageInfoBox message="globalError" type="0" />, {
                        className: 'red-background',
                    });
                });
            } else {
                dispatch(editCompanyMailFunction(value));
                setValue({
                    "acEmail": "",
                    "acType": ""
                })
            }

        }
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="block-profile-data">
                        <h4 className="title-block-profile-data">{t('osnovniPodaci')}</h4>
                        <div className="block-profile-data-content">
                            <form>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="acCompany">{t('imeKompanije')}</label>
                                            <input
                                                readOnly
                                                id='acCompany'
                                                name="acCompany"
                                                type="text"
                                                className="form-control custom-input-check form-control-sm"
                                                value={getUserInfo.acCompany}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="acAddress">{t('adresa')}</label>
                                            <input
                                                readOnly
                                                id='acAddress'
                                                name="acAddress"
                                                type="text"
                                                className="form-control custom-input-check form-control-sm"
                                                value={getUserInfo.acAddress}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="acCity">{t('grad')}</label>
                                            <input
                                                readOnly
                                                id='acCity'
                                                name="acCity"
                                                type="text"
                                                className="form-control custom-input-check form-control-sm"
                                                value={getUserInfo.acCity}
                                            />
                                        </div>
                                        {/* <div className="form-group">
                                            <label htmlFor="acPhone1">Telefon:</label>
                                            <input
                                                readOnly
                                                id='acPhone1'
                                                name="acPhone1"
                                                type="text"
                                                className="form-control custom-input-check form-control-sm"
                                                value={getUserInfo.acPhone1}
                                            />
                                        </div> */}
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="acPhone1">{t('telefon')}</label>
                                            <input
                                                readOnly
                                                id='acPhone1'
                                                name="acPhone1"
                                                type="text"
                                                className="form-control custom-input-check form-control-sm"
                                                value={getUserInfo.acPhone1}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="acCode">{t('pib')}</label>
                                            <input
                                                readOnly
                                                id='acCode'
                                                type="text"
                                                name="acCode"
                                                className="form-control custom-input-check form-control-sm"
                                                value={getUserInfo.acCode}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="acRegNo">{t('maticniBroj')}</label>
                                            <input
                                                readOnly
                                                id='acRegNo'
                                                name="acRegNo"
                                                type="text"
                                                className="form-control custom-input-check form-control-sm"
                                                value={getUserInfo.acRegNo}
                                            />
                                        </div>
                                        {/* <div className="form-group">
                                            <label htmlFor="acCompanyClassif">Klasifikacija kupaca:</label>
                                            <input
                                                readOnly
                                                id='acCompanyClassif'
                                                name="acCompanyClassif"
                                                type="number"
                                                className="form-control custom-input-check form-control-sm"
                                                value={getUserInfo.acCompanyClassif}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="acCompanyRebate">Rabatna šema:</label>
                                            <input
                                                readOnly
                                                id='acCompanyRebate'
                                                type="text"
                                                name="acCompanyRebate"
                                                className="form-control custom-input-check form-control-sm"
                                                value={getUserInfo.acCompanyRebate}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="block-profile-data">
                        <h4 className="title-block-profile-data">{t('podesEmailAdrese')}</h4>
                        <div className="block-profile-data-content">
                            <form>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="facture">{t('mailZaFakture')}</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" readOnly={user.anRoleKey == 1 ? false : true} name="facture" className="form-control" id="facture" value={values.facture} onChange={(e) => onChangeFunction(e)} />
                                                {user.anRoleKey == 1 &&
                                                    <div className="input-group-append">
                                                        <button className="btn btn-submit-email text-center" onClick={handleInput}>{t('promeni')}</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="endUser">{t('mailZaKkorisnika')}</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" readOnly={user.anRoleKey == 1 ? false : true} name="endUser" className="form-control" id="endUser" value={values.endUser} onChange={(e) => onChangeFunction(e)} />
                                                {user.anRoleKey == 1 &&
                                                    <div className="input-group-append">
                                                        <button className="btn btn-submit-email text-center" onClick={handleInput}>{t('promeni')}</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="newsletter">{t('mailaZaNewsletter')}</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" readOnly={user.anRoleKey == 1 ? false : true} name="newsletter" className="form-control" id="newsletter" value={values.newsletter} onChange={(e) => onChangeFunction(e)} />
                                                {user.anRoleKey == 1 &&
                                                    <div className="input-group-append">
                                                        <button className="btn btn-submit-email text-center" onClick={handleInput}>{t('promeni')}</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="cart">{t('mailZaPotvrduKupovine')}</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" readOnly={user.anRoleKey == 1 ? false : true} name="cart" className="form-control" id="cart" value={values.cart} onChange={(e) => onChangeFunction(e)} />
                                                {user.anRoleKey == 1 &&
                                                    <div className="input-group-append">
                                                        <button className="btn btn-submit-email text-center" onClick={handleInput}>{t('promeni')}</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-check-label" htmlFor="proFactureType">{t('zelimDaPrimamFakt')}</label>
                                            <select
                                                className="form-control user-info-profacture-input form-control form-control-sm"
                                                name="proFactureType"
                                                value={proFactureType}
                                                onChange={(e) => onChangeSelect(e)}
                                                disabled={user.anRoleKey == 1 ? false : true}
                                            >
                                                <option key={1} value={"proFactureXML"}>{t('xml')}</option>
                                                <option key={2} value={"proFactureJSON"}>{t('json')}</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="proFacture">{t('mailProfakture')}</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" readOnly={user.anRoleKey == 1 ? false : true} name="proFacture" className="form-control" id="proFacture" value={values.proFacture} onChange={(e) => onChangeFunction(e)} />
                                                {user.anRoleKey == 1 &&
                                                    <div className="input-group-append">
                                                        <button className="btn btn-submit-email text-center" onClick={handleInput}>{t('promeni')}</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="catalogXml">{t('mailZaXML')}</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" readOnly={user.anRoleKey == 1 ? false : true} name="catalogXml" className="form-control" id="catalogXml" value={values.catalogXml} onChange={(e) => onChangeFunction(e)} />
                                                {user.anRoleKey == 1 &&
                                                    <div className="input-group-append">
                                                        <button className="btn btn-submit-email text-center" onClick={handleInput}>{t('promeni')}</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="block-profile-data">
                        <h4 className="title-block-profile-data">{t('vizuali')}</h4>
                        <p>{t('ponZaKupca')}<br />{t('dodajteMarzu')}</p>
                        <div className="block-profile-data-content">
                            <div className="delivery-file-att-item">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>{t('logo')}<br /><small className="recommended-img-size">{t('dimenzLogotipa')}</small></td>
                                            <td>
                                                <label>
                                                    <span className="delivery-label">{values.acCompanyLogo.trim() != "" ? t('zameniSliku') : t('snimiSliku')} <i className="far fa-file-image"></i></span>
                                                    <input type="file" name="acCompanyLogo" onChange={handleFileInput} onClick={resetFileValue} />
                                                </label>
                                            </td>
                                            {logoImg ? (
                                                <td>
                                                    <div className="delivery-img-preview">
                                                        <img alt="document not found" className="img-fluid" src={URL.createObjectURL(logoImg)} />
                                                    </div>
                                                </td>
                                            ) : values.acCompanyLogo.trim() != "" ? (
                                                <td>
                                                    <div className="delivery-img-preview">
                                                        <img alt="logo" className="img-fluid" src={"https://resource.ewe.rs" + values.acCompanyLogo} />
                                                    </div>
                                                </td>
                                            ) : ("")}
                                            {isLogo && (
                                                <td>
                                                    <label>
                                                        <span onClick={removeImage} data-delete='acCompanyLogo' className="delete-label-profile"><i className="fas fa-times"></i> {t('ukloni')}</span>
                                                    </label>
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td>{t('header')}<br /><small className="recommended-img-size">{t('dimenzHeadera')}</small></td>
                                            <td>
                                                <label>
                                                    <span className="delivery-label">{values.acCompanyMemoHeader.trim() != "" ? t('zameniSliku') : t('snimiSliku')} <i className="far fa-file-image"></i></span>
                                                    <input type="file" name="acCompanyMemoHeader" onChange={handleFileInput} onClick={resetFileValue} />
                                                </label>
                                            </td>
                                            {headerImg ? (
                                                <td>
                                                    <div className="delivery-img-preview">
                                                        <img alt="document not found" className="img-fluid" src={URL.createObjectURL(headerImg)} />
                                                    </div>
                                                </td>
                                            ) : values.acCompanyMemoHeader.trim() != "" ? (
                                                <td>
                                                    <div className="delivery-img-preview">
                                                        <img alt="document not found" className="img-fluid" src={"https://resource.ewe.rs" + values.acCompanyMemoHeader} />
                                                    </div>
                                                </td>
                                            ) : ("")}
                                            {isHeader && (
                                                <td>
                                                    <label>
                                                        <span onClick={removeImage} data-delete='acCompanyMemoHeader' className="delete-label-profile"><i className="fas fa-times"></i> {t('ukloni')}</span>
                                                    </label>
                                                </td>
                                            )}
                                        </tr>
                                        <tr>
                                            <td>Footer:<br /><small className="recommended-img-size">{t('dimenzFootera')}</small></td>
                                            <td>
                                                <label>
                                                    <span className="delivery-label">{values.acCompanyMemoFooter.trim() != "" ? t('zameniSliku') : t('snimiSliku')} <i className="far fa-file-image"></i></span>
                                                    <input type="file" name="acCompanyMemoFooter" onChange={handleFileInput} onClick={resetFileValue} />
                                                </label>
                                            </td>
                                            {footerImg ? (
                                                <td>
                                                    <div className="delivery-img-preview">
                                                        <img alt="document not found" className="img-fluid" src={URL.createObjectURL(footerImg)} />
                                                    </div>
                                                </td>
                                            ) : values.acCompanyMemoFooter.trim() != "" ? (
                                                <td>
                                                    <div className="delivery-img-preview">
                                                        <img alt="document not found" className="img-fluid" src={"https://resource.ewe.rs" + values.acCompanyMemoFooter} />
                                                    </div>
                                                </td>
                                            ) : ("")}
                                            {isFooter && (
                                                <td>
                                                    <label>
                                                        <span onClick={removeImage} data-delete='acCompanyMemoFooter' className="delete-label-profile"><i className="fas fa-times"></i> {t('ukloni')}</span>
                                                    </label>
                                                </td>
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <small className="recommended-img-size">{t('maxVelSlika')}</small>
                        </div>
                        {/* <div className="text-right">
                            <div className="btn-sbmit-delviery text-center" onClick={handleSubmit}>{t('promeni')}} podatke</div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyProfile