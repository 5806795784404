import React, { useState, useEffect, useMemo, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { axiosinstance } from '../configAxios';

import { getInvoice, getBankAccount, globalStatistic } from '../redux/actions/otherActions';
import { getDeliveryCity } from '../redux/actions/cartActions';
import { getPageSeo } from '../redux/actions/pageActions';

import FormInput from '../components/form/FormInput';
import MessageInfoBox from '../components/other/MessageInfoBox';
import PageBanner from '../components/banner/PageBanner';
import ModalDelivery from '../components/modal/ModalDelivery';
import ModalConfirm from '../components/modal/ModalConfirm';
import PageHelmet from '../components/helmet/PageHelmet';
import MaterialTable from "material-table";
import PatchedPagination from '../components/other/PatchedPagination'
import { Paper } from '@material-ui/core';
import { addToCart } from '../redux/actions/cartActions'
import '../css/DeliveryPage.css';
import { useTranslation } from 'react-i18next';

function CurrencyFormatFunction(num) {
    return num == null ? 0 : num.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

const DeliveryPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const tableRef = createRef();
    const getInvoiceItems = useSelector((state) => state.getInvoiceItems.items);
    const getBankAccountItems = useSelector((state) => state.getBankAccountItems.items);
    const deliveryCityList = useSelector((state) => state.getDeliveryCity.items);
    const getUserInfo = useSelector((state) => state.userInfo.userInfo.company);
    const [pageSeoData, setPageSeoData] = useState({});
    const [item, setItem] = useState([]);
    const getActiveCartFunction = useSelector((state) => state.getActiveCartFunction);

    let invoiceItems = []
    getInvoiceItems.filter(item => {
        invoiceItems.push({ _id: item._id, acName: item.acDocKey })
    })

    let bankAccountItems = []
    getBankAccountItems.filter(item => {
        bankAccountItems.push({ _id: item._id, acName: item.acBankAccount })
    })

    const [bankOptions, setBankOptions] = useState({ value: "" })
    const [show, setShow] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [isInvoiceTable, setIsInvoiceTable] = useState(false)
    const [imgName1, setImgName1] = useState("");
    const [imgName2, setImgName2] = useState("");
    const [isSetViaInput1, setIsSetViaInput1] = useState(false);
    const [isSetViaInput2, setIsSetViaInput2] = useState(false);
    const [currentDocKey, setCurrentDocKey] = useState(false);
    const { t } = useTranslation('deliveryPage');

    useEffect(() => {
        dispatch(getPageSeo(350)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(getInvoice());
        dispatch(getBankAccount());
        dispatch(getDeliveryCity());
        dispatch(globalStatistic(350, 'PAGE'));

    }, [])

    const itemsCities = useMemo(
        () =>
            deliveryCityList.map((item) => ({
                id: item._id,
                postCode: item.acPostCode,
                city: item.acCity,
                label: item.acPostCode + ' ' + item.acCity,
            })),
        [deliveryCityList]
    );

    const [values, setValues] = useState({
        acDocKey: "",
        acEndUser: "",
        acEndUserAddress: "",
        acPostCode: "",
        acPhone1: "",
        acPhone2: "",
        isRansomDelivery: 1,
        anRansomAmount: "",
        acPayMethod: false,
        acCompanyBankAccount: "",
        acCompanyReceipt: "",
    })

    const [errors, setErrors] = useState({
        acDocKey: 1,
        acEndUser: 1,
        acEndUserAddress: 1,
        acPostCode: 1,
        acPhone1: 1,
        // acPhone2: 1,
        anRansomAmount: 1,
        acCompanyBankAccount: 1,
        acCompanyReceipt: 1,
        acDocument1: 1,
        acDocument2: 1,
    })

    const [validInput, setValidInput] = useState({
        acDocKey: 0,
        acEndUser: 0,
        acEndUserAddress: 0,
        acPostCode: 0,
        acPhone1: 0,
        // acPhone2: 0,
        anRansomAmount: 0,
        acCompanyBankAccount: 0,
        acCompanyReceipt: 0,
        acDocument1: 0,
        acDocument2: 0,
    })

    const onChangeFunction = (e) => {
        // console.group('Promena naplate na adresi ili metode placanja je započeta...');
        // console.log('Promena polja: ', e.target.name);

        setValues({ ...values, [e.target.name]: e.target.value });

        if (e.target.name == "acPayMethod") {
            setValues({ ...values, [e.target.name]: e.target.checked })
        } else if (e.target.name == "isRansomDelivery") {
            if (e.target.value == 0) {
                setValues({
                    ...values,
                    anRansomAmount: "",
                    isRansomDelivery: 0,
                    acCompanyBankAccount: "",
                    acPayMethod: false
                })
                setBankOptions({
                    value: ""
                })
                setErrors({
                    ...errors,
                    anRansomAmount: 0,
                    acCompanyBankAccount: 0,
                    acCompanyReceipt: values.acCompanyReceipt == '' || !values.acCompanyReceipt.replace(/\s/g, '').length ? 1 : 0,
                })
            } else {
                setErrors({
                    ...errors,
                    anRansomAmount: values.anRansomAmount == '' ? 1 : 0,
                    acCompanyBankAccount: values.acCompanyBankAccount == '' ? 1 : 0,
                    acCompanyReceipt: values.acCompanyReceipt == '' || !values.acCompanyReceipt.replace(/\s/g, '').length ? 1 : 0,
                })
            }
        } else if (e.target.name == "acCompanyBankAccount") {
            if (e.target.value == "novi racun") {
                setValues({ ...values, acCompanyBankAccount: "" })
                setErrors({
                    ...errors,
                    acCompanyBankAccount: 1,
                })
            } else {
                // console.log('acCompanyBankAccount -> e.target.value', e.target.value);
                // console.log('acCompanyBankAccount -> values', values);
                setErrors({
                    ...errors,
                    acCompanyBankAccount: e.target.value == '' ? 1 : 0,
                })

            }
            // console.log('acCompanyBankAccount -> errors', errors);
        } else if (e.target.name == 'acDocKey') {
            // console.log(invoiceItems);
            // console.log(orderHistoryList, 'orderHistoryList');
            if (e.target.value) {

                const invoiceDetails = getInvoiceItems.find(obj => obj.acDocKey === e.target.value);
                if (invoiceDetails) {
                    setItem(invoiceDetails);
                    setIsInvoiceTable(true);
                    // console.log("Pronađen je objekat sa traženim acDocKey:", invoiceDetails);
                    if (invoiceDetails.b2bEndUserDelvery) {
                        if (!currentDocKey) {
                            setCurrentDocKey(true);
                        }
                        // console.log('Ima prethodno popunjenu formu (b2bEndUserDelvery): ', invoiceDetails.b2bEndUserDelvery);
                        var acPostCode = '';
                        if (invoiceDetails.b2bEndUserDelvery.anPostCode) {
                            acPostCode = deliveryCityList.find(obj => obj.acPostCode.includes(invoiceDetails.b2bEndUserDelvery.anPostCode));
                        }

                        // console.log(acPostCode, 'acPostCode');

                        setErrors({
                            ...errors,
                            acDocKey: 0,
                            acEndUser: 0,
                            acEndUserAddress: 0,
                            acPostCode: 0,
                            acPhone1: 0,
                            // acPhone2: 0,
                            anRansomAmount: 0,
                            acCompanyBankAccount: 0,
                            acCompanyReceipt: 0,
                            acDocument1: 0,
                            acDocument2: 0,
                        })
                        // console.log(acPostCode, 'acPOstCode');
                        setValues({
                            ...values,
                            acDocKey: invoiceDetails.b2bEndUserDelvery._id,
                            acEndUser: invoiceDetails.b2bEndUserDelvery.acEndUser ? invoiceDetails.b2bEndUserDelvery.acEndUser : "",
                            acEndUserAddress: invoiceDetails.b2bEndUserDelvery.acEndUserAddress ? invoiceDetails.b2bEndUserDelvery.acEndUserAddress : "",
                            acPostCode: acPostCode ? acPostCode.acPostCode + ' ' + acPostCode.acCity : "",
                            city: acPostCode.acCity,
                            postCode: acPostCode.acPostCode,
                            acPhone1: invoiceDetails.b2bEndUserDelvery.acPhone1 ? invoiceDetails.b2bEndUserDelvery.acPhone1 : "",
                            acPhone2: invoiceDetails.b2bEndUserDelvery.acPhone2 ? invoiceDetails.b2bEndUserDelvery.acPhone2 : "",
                            isRansomDelivery: invoiceDetails.b2bEndUserDelvery.isRansomDelivery ? invoiceDetails.b2bEndUserDelvery.isRansomDelivery : 0,
                            anRansomAmount: invoiceDetails.b2bEndUserDelvery.anRansomAmount ? invoiceDetails.b2bEndUserDelvery.anRansomAmount : "",
                            acPayMethod: invoiceDetails.b2bEndUserDelvery.acPayMethod == 'Da' ? true : false,
                            acCompanyBankAccount: invoiceDetails.b2bEndUserDelvery.acCompanyBankAccount ? invoiceDetails.b2bEndUserDelvery.acCompanyBankAccount : "",
                            acCompanyReceipt: invoiceDetails.b2bEndUserDelvery.acCompanyReceipt ? invoiceDetails.b2bEndUserDelvery.acCompanyReceipt : "",
                        });

                        setBankOptions({
                            value: 'novi-racun'
                        })

                        setImgName1(invoiceDetails.b2bEndUserDelvery.acDocument1 ? { name: invoiceDetails.b2bEndUserDelvery.acDocument1 } : "")
                        setImgName2(invoiceDetails.b2bEndUserDelvery.acDocument2 ? { name: invoiceDetails.b2bEndUserDelvery.acDocument2 } : "")
                    } else {
                        if (currentDocKey) {
                            setShowConfirmModal(true);
                        } else {
                            setCurrentDocKey(true);
                        }
                    }
                }
            } else {
                setIsInvoiceTable(false);
            }
        }

        // if (e.target.name == "isRansomDelivery" && e.target.value == 0) {
        //     console.log('Target: ', e.target.name, 'ima vrednost 0');
        //     setValues({
        //         ...values,
        //         anRansomAmount: "",
        //         isRansomDelivery: 0,
        //         acCompanyBankAccount: "",
        //         acPayMethod: false
        //     })
        //     setBankOptions({
        //         value: ""
        //     })
        //     setErrors({
        //         ...errors,
        //         anRansomAmount: 0,
        //         acCompanyBankAccount: 0,
        //         acCompanyReceipt: values.acCompanyReceipt == '' || !values.acCompanyReceipt.replace(/\s/g, '').length ? 1 : 0,
        //     })
        // } else {
        //     console.log('Target: ', e.target.name, 'ima vrednost 1');
        //     console.log('acCompanyBankAccount: ', values.acCompanyBankAccount);
        //     console.log('acCompanyBankAccount target value ', e.target.value);
        //     if (e.target.name == "acCompanyBankAccount" && e.target.value == "novi racun") {

        //         setValues({ ...values, acCompanyBankAccount: "" })
        //         setErrors({
        //             ...errors,
        //             acCompanyBankAccount: 1,
        //         })
        //         console.log('Usao u if setuje se error na 1 ', errors);
        //         console.log('validInput ', validInput);
        //     } else if (values.isRansomDelivery) {
        //         console.log('Usao u else if setuje se errors: ', errors);
        //         setErrors({
        //             ...errors,
        //             anRansomAmount: values.anRansomAmount == '' ? 1 : 0,
        //             acCompanyBankAccount: values.acCompanyBankAccount == '' ? 1 : 0,
        //             acCompanyReceipt: values.acCompanyReceipt == '' || !values.acCompanyReceipt.replace(/\s/g, '').length ? 1 : 0,
        //         })
        //         console.log('errors: ', errors);
        //     } else if (e.target.name == "isRansomDelivery") {
        //         console.log('Usao u else setuje se errors: ', errors);
        //         setErrors({
        //             ...errors,
        //             anRansomAmount: 1,
        //             acCompanyBankAccount: 1,
        //             acCompanyReceipt: values.acCompanyReceipt == '' || !values.acCompanyReceipt.replace(/\s/g, '').length ? 1 : 0,
        //         })
        //     }
        // }
        // console.groupEnd();
    }

    const cityChangeFunction = (e, val) => {
        // console.log(val.label);
        // console.log(val.city);
        // console.log(val.postCode);
        setValues({ ...values, acPostCode: val.label, city: val.city, postCode: val.postCode });
    }

    const validateAutocomplete = (e) => {
        // console.log('triggered validateAutocomplete', e.target.name, e.target.value);
        if (!e.target.value || !e.target.value.replace(/\s/g, '').length) {
            setValidInput({ ...validInput, [e.target.name]: 1 });
            setErrors({ ...errors, [e.target.name]: 1 });
        } else {
            setValidInput({ ...validInput, [e.target.name]: 2 });
            setErrors({ ...errors, [e.target.name]: 0 });
        }
    }

    const setErrorsFunction = (name, val) => {
        // console.log('triggered setErrorsFunction', name, val);
        // console.log('triggered setErrorsFunction errors', errors);
        // console.log('triggered setErrorsFunction validInput', validInput);
        // console.log('triggered setErrorsFunction values', values);
        // console.log('bankOptions.value == "novi racun"', bankOptions.value);
        if (name != 'acCompanyBankAccount') {
            setErrors({ ...errors, [name]: val });
        } else {
            if (bankOptions.value != "novi racun") {
                setErrors({ ...errors, [name]: val });
            }
        }
    };

    const handleClose = () => {
        setShow(false);
    };
    const handleModalConfirmClose = () => {
        setShowConfirmModal(false);
    };
    const functionActionConfirm = () => {
        // console.log('Pokrecemo restart ------------------------------->');
        setShowConfirmModal(false);
        //resetovanje inputa i provera obaveznih polja
        setValidInput({
            ...validInput,
            acDocKey: 0,
            acEndUser: 0,
            acEndUserAddress: 0,
            acPostCode: 0,
            acPhone1: 0,
            // acPhone2: 0,
            anRansomAmount: 0,
            acCompanyBankAccount: 0,
            acCompanyReceipt: 0,
            acDocument1: 0,
            acDocument2: 0,
        });

        setErrors({
            ...errors,
            acDocKey: 1,
            acEndUser: 1,
            acEndUserAddress: 1,
            acPostCode: 1,
            acPhone1: 1,
            // acPhone2: 1,
            anRansomAmount: 1,
            acCompanyBankAccount: 1,
            acCompanyReceipt: 1,
            acDocument1: 1,
            acDocument2: 1,
        })

        setValues({
            ...values,
            // acDocKey: "",
            acEndUser: "",
            acEndUserAddress: "",
            acPostCode: "",
            acPhone1: "",
            acPhone2: "",
            isRansomDelivery: 1,
            anRansomAmount: "",
            acPayMethod: false,
            acCompanyBankAccount: "",
            acCompanyReceipt: "",
        });

        setBankOptions({
            value: ""
        })

        setImgName1("");
        setImgName2("");
        setIsSetViaInput1(false);
        setIsSetViaInput2(false);
    };



    const sendData = (e) => {

        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        // console.log(values, 'values');
        // console.log(values.postCode, 'values');
        const uploadPath = "endUser";

        const formData = new FormData();
        let arrImg = [];
        // console.log(isSetViaInput1, isSetViaInput2, 'isSetViaInput');
        if (isSetViaInput1) {
            formData.append('document', imgName1);
            // arrImg.push(imgName1);
        }
        if (isSetViaInput2) {
            formData.append('document', imgName2);
            // arrImg.push(imgName2);
        }

        // if (arrImg.length === 0) {
        //     if (imgName1) {
        //         arrImg.push(imgName1);
        //     }
        //     if (imgName2) {
        //         arrImg.push(imgName2);
        //     }
        // }
        if (formData.entries().next().done) { // vraca true ako je prazan formData
            const attributes = {};

            getUserInfo.email.forEach((item) => {
                if (item.acType == 'endUser') {
                    attributes['acCompanyMail'] = item.acEmail;
                }
            });

            attributes['anCompanyKey'] = getUserInfo.anCompanyKey
            attributes['acDocKey'] = values.acDocKey
            attributes['acEndUser'] = values.acEndUser
            attributes['acEndUserAddress'] = values.acEndUserAddress
            attributes['acCity'] = values.city
            attributes['anPostCode'] = values.postCode.slice(3)
            attributes['acPhone1'] = values.acPhone1
            attributes['acPhone2'] = values.acPhone2
            attributes['isRansomDelivery'] = values.isRansomDelivery
            attributes['anRansomAmount'] = values.anRansomAmount ? values.anRansomAmount : 0.0;
            attributes['acPayMethod'] = values.acPayMethod == true ? "Da" : "Ne"
            attributes['acCompanyBankAccount'] = values.acCompanyBankAccount
            attributes['acCompanyReceipt'] = values.acCompanyReceipt
            attributes['acDocument1'] = `${imgName1.name}`
            attributes['acDocument2'] = `${imgName2.name}`
            attributes['_id'] = values.acDocKey

            sendDeliveryData(attributes);
        } else { // ukoliko formData nije prazan, odnosno ukoliko je barem jedan fajl uploadovan
            if (isSetViaInput1 && isSetViaInput2) {
                try {
                    axios.post('https://newb2b.ewe.rs:5004/image-upload/' + uploadPath + '', formData)
                        .then(res => {
                            if (res.data.isOk == 1) {

                                const attributes = {};

                                getUserInfo.email.forEach((item) => {
                                    if (item.acType == 'endUser') {
                                        attributes['acCompanyMail'] = item.acEmail;
                                    }
                                });

                                attributes['anCompanyKey'] = getUserInfo.anCompanyKey
                                attributes['acDocKey'] = values.acDocKey
                                attributes['acEndUser'] = values.acEndUser
                                attributes['acEndUserAddress'] = values.acEndUserAddress
                                attributes['acCity'] = values.city
                                attributes['anPostCode'] = values.postCode.slice(3)
                                attributes['acPhone1'] = values.acPhone1
                                attributes['acPhone2'] = values.acPhone2
                                attributes['isRansomDelivery'] = values.isRansomDelivery
                                attributes['anRansomAmount'] = values.anRansomAmount ? values.anRansomAmount : 0.0;
                                attributes['acPayMethod'] = values.acPayMethod == true ? "Da" : "Ne"
                                attributes['acCompanyBankAccount'] = values.acCompanyBankAccount
                                attributes['acCompanyReceipt'] = values.acCompanyReceipt
                                attributes['acDocument1'] = `/media/${uploadPath}/${res.data.fileNames[0]}`
                                attributes['acDocument2'] = `/media/${uploadPath}/${res.data.fileNames[1]}`
                                attributes['_id'] = values.acDocKey

                                sendDeliveryData(attributes);

                            } else {
                                toast(<MessageInfoBox message={res.data.msg} type={res.data.isOk} />, {
                                    className: res.data.isOk == 1 ? 'green-background' : 'red-background',
                                });
                                setShow(false)
                            }
                        })
                } catch (error) {
                    console.log(error);
                    toast(<MessageInfoBox message="globalError" type="0" />, {
                        className: 'red-background',
                    });
                }
            } else if (isSetViaInput1) {
                try {
                    axios.post('https://newb2b.ewe.rs:5000/image-upload/' + uploadPath + '', formData)
                        .then(res => {
                            if (res.data.isOk == 1) {

                                const attributes = {};

                                getUserInfo.email.forEach((item) => {
                                    if (item.acType == 'endUser') {
                                        attributes['acCompanyMail'] = item.acEmail;
                                    }
                                });

                                attributes['anCompanyKey'] = getUserInfo.anCompanyKey
                                attributes['acDocKey'] = values.acDocKey
                                attributes['acEndUser'] = values.acEndUser
                                attributes['acEndUserAddress'] = values.acEndUserAddress
                                attributes['acCity'] = values.city
                                attributes['anPostCode'] = values.postCode.slice(3)
                                attributes['acPhone1'] = values.acPhone1
                                attributes['acPhone2'] = values.acPhone2
                                attributes['isRansomDelivery'] = values.isRansomDelivery
                                attributes['anRansomAmount'] = values.anRansomAmount ? values.anRansomAmount : 0.0;
                                attributes['acPayMethod'] = values.acPayMethod == true ? "Da" : "Ne"
                                attributes['acCompanyBankAccount'] = values.acCompanyBankAccount
                                attributes['acCompanyReceipt'] = values.acCompanyReceipt
                                attributes['acDocument1'] = `/media/${uploadPath}/${res.data.fileName}`
                                attributes['acDocument2'] = `${imgName2.name}`
                                attributes['_id'] = values.acDocKey

                                sendDeliveryData(attributes);

                            } else {
                                toast(<MessageInfoBox message={res.data.msg} type={res.data.isOk} />, {
                                    className: res.data.isOk == 1 ? 'green-background' : 'red-background',
                                });
                                setShow(false)
                            }
                        })
                } catch (error) {
                    console.log(error);
                    toast(<MessageInfoBox message="globalError" type="0" />, {
                        className: 'red-background',
                    });
                }
            } else if (isSetViaInput2) {
                try {
                    axios.post('https://newb2b.ewe.rs:5000/image-upload/' + uploadPath + '', formData)
                        .then(res => {
                            if (res.data.isOk == 1) {

                                const attributes = {};

                                getUserInfo.email.forEach((item) => {
                                    if (item.acType == 'endUser') {
                                        attributes['acCompanyMail'] = item.acEmail;
                                    }
                                });

                                attributes['anCompanyKey'] = getUserInfo.anCompanyKey
                                attributes['acDocKey'] = values.acDocKey
                                attributes['acEndUser'] = values.acEndUser
                                attributes['acEndUserAddress'] = values.acEndUserAddress
                                attributes['acCity'] = values.city
                                attributes['anPostCode'] = values.postCode.slice(3)
                                attributes['acPhone1'] = values.acPhone1
                                attributes['acPhone2'] = values.acPhone2
                                attributes['isRansomDelivery'] = values.isRansomDelivery
                                attributes['anRansomAmount'] = values.anRansomAmount ? values.anRansomAmount : 0.0;
                                attributes['acPayMethod'] = values.acPayMethod == true ? "Da" : "Ne"
                                attributes['acCompanyBankAccount'] = values.acCompanyBankAccount
                                attributes['acCompanyReceipt'] = values.acCompanyReceipt
                                attributes['acDocument1'] = `${imgName1.name}`
                                attributes['acDocument2'] = `/media/${uploadPath}/${res.data.fileName}`
                                attributes['_id'] = values.acDocKey

                                sendDeliveryData(attributes);

                            } else {
                                toast(<MessageInfoBox message={res.data.msg} type={res.data.isOk} />, {
                                    className: res.data.isOk == 1 ? 'green-background' : 'red-background',
                                });
                                setShow(false)
                            }
                        })
                } catch (error) {
                    console.log(error);
                    toast(<MessageInfoBox message="globalError" type="0" />, {
                        className: 'red-background',
                    });
                }
            }

        }
    }

    const sendDeliveryData = (attributes) => {
        // console.log(attributes, 'attributes from sendDeliveryData');
        try {
            const configRes = axiosinstance(attributes, '8224', 'postEndUserDeliverySave');

            axios(configRes)
                .then(function (response) {
                    // console.log(response, 'response f-je postEndUserDeliverySave nakon uspesnog uploada fajlova');
                    // console.log(attributes, 'attributes f-je postEndUserDeliverySave nakon uspesnog uploada fajlova');
                    if (response.data.isOk == 1) {
                        axios({
                            method: "POST",
                            url: "https://newb2b.ewe.rs:5000/delivery",
                            data: {
                                values: response.data.response.eud,
                                acPostCode: values.acPostCode,
                                clerkResponsible: getUserInfo.clerkResponsible[0].acEmail,
                                acCompanyMail: attributes.acCompanyMail,
                            }
                        })
                        toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                            className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                        });
                        dispatch(getInvoice());
                        setTimeout(() => {
                            history.push('/');
                        }, 2000);

                        //resetovanje inputa i provera obaveznih polja
                        setValidInput({
                            ...validInput,
                            acDocKey: 0,
                            acEndUser: 0,
                            acEndUserAddress: 0,
                            acPostCode: 0,
                            acPhone1: 0,
                            // acPhone2: 0,
                            anRansomAmount: 0,
                            acCompanyBankAccount: 0,
                            acCompanyReceipt: 0,
                            acDocument1: 0,
                            acDocument2: 0,
                        });

                        setErrors({
                            ...errors,
                            acDocKey: 1,
                            acEndUser: 1,
                            acEndUserAddress: 1,
                            acPostCode: 1,
                            acPhone1: 1,
                            // acPhone2: 1,
                            anRansomAmount: 1,
                            acCompanyBankAccount: 1,
                            acCompanyReceipt: 1,
                            acDocument1: 1,
                            acDocument2: 1,
                        })

                        setValues({
                            ...values,
                            acDocKey: "",
                            acEndUser: "",
                            acEndUserAddress: "",
                            acPostCode: "",
                            acPhone1: "",
                            acPhone2: "",
                            isRansomDelivery: 1,
                            anRansomAmount: "",
                            acPayMethod: false,
                            acCompanyBankAccount: "",
                            acCompanyReceipt: "",
                        });

                        setBankOptions({
                            value: ""
                        })

                        setImgName1("")
                        setImgName2("")
                        setIsSetViaInput1(false);
                        setIsSetViaInput2(false);

                        setShow(false)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toast(<MessageInfoBox message="globalError" type="0" />, {
                        className: 'red-background',
                    });
                });
        } catch (error) {
            console.log(error)
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        }
    }

    const handleFileInput = (e) => {
        e.preventDefault();

        if (e.target.name == "acDocument1") {
            setImgName1(e.target.files[0])
            setValidInput({
                ...validInput,
                acDocument1: 2,
            });
            setErrors({
                ...errors,
                acDocument1: 0
            })
            setIsSetViaInput1(true);
        } else {
            // console.log(e.target.files[0], 'upload slike');
            setImgName2(e.target.files[0])
            setValidInput({
                ...validInput,
                acDocument2: 2,
            });
            setErrors({
                ...errors,
                acDocument2: 0
            })
            setIsSetViaInput2(true);
        }
    }

    const resetFileValue = (e) => {
        e.target.value = null
    }

    const handleSubmit = (e) => {

        // console.group('Kliknuto dugme "Potvrdi", startuje se finalna validacija...');
        // console.log(validInput, 'function: handleSubmit, local state: validInput');
        // console.log(errors, 'function: handleSubmit, local state: errors');
        // console.log('Setujemo vrednosti lokalnog stejta validInput');

        setValidInput({
            ...validInput,
            acDocKey: values.acDocKey == '' ? 1 : 2,
            acEndUser: values.acEndUser == '' || !values.acEndUser.replace(/\s/g, '').length ? 1 : 2,
            acEndUserAddress: values.acEndUserAddress == '' || !values.acEndUserAddress.replace(/\s/g, '').length ? 1 : 2,
            acPostCode: values.acPostCode == '' || values.acPostCode == null || !values.acPostCode.replace(/\s/g, '').length ? 1 : 2,
            acPhone1: values.acPhone1 == '' ? 1 : 2,
            // acPhone2: values.acPhone2 == '' ? 1 : 2,
            anRansomAmount: values.anRansomAmount == '' && values.isRansomDelivery == 1 ? 1 : 2,
            acCompanyBankAccount: values.acCompanyBankAccount == '' && values.isRansomDelivery == 1 ? 1 : 2,
            acCompanyReceipt: values.acCompanyReceipt == '' || !values.acCompanyReceipt.replace(/\s/g, '').length && values.isRansomDelivery == 1 ? 1 : 2,
            acDocument1: imgName1 == '' ? 1 : 2,
            acDocument2: imgName2 == '' ? 1 : 2,
        });

        e.preventDefault();

        if (errors.acDocKey == 0
            && errors.acEndUser == 0
            && errors.acEndUserAddress == 0
            && errors.acPostCode == 0
            && errors.acPhone1 == 0
            && errors.anRansomAmount == 0
            && errors.acCompanyBankAccount == 0
            && errors.acCompanyReceipt == 0
            && errors.acDocument1 == 0
            && errors.acDocument2 == 0) {

            // console.log('Validacija je uspešna, prikazujemo modal.');
            setShow(true);

        } else {
            // console.log('Validacija je neuspešna, prikazujemo error poruku.');
            toast(<MessageInfoBox message='errorReqiuredFormInputs' type={0} />, {
                className: 'red-background',
            });
        }
        // console.groupEnd();

    };

    const addItemToCart = (item2) => {
        dispatch(addToCart(item2, item2.anQty, getActiveCartFunction))
        handleClose()
    }

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-catalog-content delivery-form-page">
                <div className="container-fluid-custom">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="banner-image-on-top-page">
                                <PageBanner categoryType="" categoryId="" pageId="350" />
                            </div>
                            <div className="delivery-form">
                                <div className="delivery-form-title">{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('podOPodZahteva')}</div>
                                {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                                <div className="delivery-form-body">
                                    <div className="form-body-holder-delivery">
                                        <div className="form-delivery-holder">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="info-delivery-box-holder">
                                                        <div className="global-info-delivery-content">
                                                            <h4>{t('narucilac')}</h4>
                                                            < div className="info-delivery-content-item" > {getUserInfo.acCompany}</div>
                                                            <div className="info-delivery-content-item">{getUserInfo.acAddress}</div>
                                                            <div className="info-delivery-content-item">PIB: {getUserInfo.acCode}</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group delivery-form-input-margin">
                                                                    <FormInput
                                                                        type="select"
                                                                        name="acDocKey"
                                                                        label="Izbor fakture"
                                                                        isRequired="1"
                                                                        messageError={t('obaveznoPolje')}
                                                                        options={invoiceItems}
                                                                        value={values['acDocKey']}
                                                                        onChange={(e) => { onChangeFunction(e) }}
                                                                        validInputValue={validInput.acDocKey}
                                                                        setErrorsFunc={setErrorsFunction}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className={`col-sm-12 ${isInvoiceTable ? 'table-exit' : 'table-enter'}`}>
                                                                <h5>{t('stavkSaFak')}</h5>
                                                                {isInvoiceTable &&
                                                                    <MaterialTable
                                                                        components={{
                                                                            // Pagination: PatchedPagination,
                                                                            // Container: props => <Paper {...props} elevation={0} />
                                                                        }}
                                                                        localization={{
                                                                            header: {
                                                                                actions: ''
                                                                            },
                                                                            // pagination: {
                                                                            //     labelRowsSelect: '',
                                                                            //     firstAriaLabel: 'Prva strana',
                                                                            //     firstTooltip: 'Prva strana',
                                                                            //     previousAriaLabel: 'Prethodna strana',
                                                                            //     previousTooltip: 'Prethodna strana',
                                                                            //     nextAriaLabel: 'Sledeća strana',
                                                                            //     nextTooltip: 'Sledeća strana',
                                                                            //     lastAriaLabel: 'Poslednja strana',
                                                                            //     lastTooltip: 'Poslednja strana',
                                                                            // },
                                                                        }}
                                                                        data={item.itemList}
                                                                        tableRef={tableRef}
                                                                        columns={[
                                                                            // {
                                                                            //     title: <>P <i className="fas fa-sort mx-1"></i></>,
                                                                            //     field: "anNo",
                                                                            // },
                                                                            {
                                                                                title: <>{t('artikal')} <i className="fas fa-sort mx-1"></i></>,
                                                                                field: "acProduct",
                                                                                type: "string",
                                                                            },
                                                                            {
                                                                                title: "Brend",
                                                                                field: "acDept",
                                                                            },
                                                                            {
                                                                                title: <>{t('naziv')} <i className="fas fa-sort mx-1"></i></>,
                                                                                field: "acName",
                                                                                render: item2 => {
                                                                                    return <div className="order-status-item" title={item2.acName}>{item2.acDept} {item2.acName}</div>
                                                                                },
                                                                                // cellStyle: { minWidth: "250px", width: "250px", padding: "8px" }
                                                                            },
                                                                            {
                                                                                title: <>{t('kolicina')} <i className="fas fa-sort mx-1"></i></>,
                                                                                field: "anQty",
                                                                            },
                                                                            {
                                                                                title: <>{t('jedCena')} <i className="fas fa-sort mx-1"></i></>,
                                                                                field: "anPrice",
                                                                                render: item2 => {
                                                                                    return CurrencyFormatFunction(item2.anPrice) + ' ' + item.acCurrency
                                                                                }
                                                                            },
                                                                            {
                                                                                title: <>{t('porez')} <i className="fas fa-sort mx-1"></i></>,
                                                                                field: "anVAT",
                                                                                render: item2 => {
                                                                                    return (item2.anVAT + "%");
                                                                                }
                                                                            },
                                                                            {
                                                                                title: <>{t('ukupno')} <i className="fas fa-sort mx-1"></i></>,
                                                                                field: "anValue",
                                                                                render: item2 => {
                                                                                    return CurrencyFormatFunction(item2.anValue) + ' ' + item.acCurrency
                                                                                }
                                                                            },
                                                                            // {
                                                                            //     title: "",
                                                                            //     render: item2 => {
                                                                            //         return (
                                                                            //             <div onClick={() => addItemToCart(item2)}>
                                                                            //                 <div className="text-center btn btn-success w-100 btn-sm">
                                                                            //                     <div className="add-button"><i className="fas fa-shopping-cart"></i>&nbsp; Dodaj
                                                                            //                     </div>
                                                                            //                 </div>
                                                                            //             </div>
                                                                            //         )
                                                                            //     }
                                                                            // },
                                                                        ]}
                                                                        options={{
                                                                            // pageSize: 5,
                                                                            // pageSizeOptions: [5, 10, 20],
                                                                            headerStyle: {
                                                                                // whiteSpace: "nowrap",
                                                                                borderBottom: "2px solid #dee2e6",
                                                                                fontWeight: "bold",
                                                                                padding: "8px"
                                                                            },
                                                                            rowStyle: {
                                                                                // whiteSpace: "nowrap",
                                                                            },
                                                                            cellStyle: {
                                                                                minWidth: "120px", width: "120px", padding: "8px"
                                                                            },
                                                                            search: false,
                                                                            showTitle: false,
                                                                            toolbar: false,
                                                                            filtering: false,
                                                                            draggable: false,
                                                                            thirdSortClick: false,
                                                                            paging: false,
                                                                            // maxBodyHeight: "180px",
                                                                        }}
                                                                        title=""
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="delivery-form-title">{t('podOisporuci')}</div>
                                <div className="delivery-form-info">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group delivery-form-input-margin">
                                                <FormInput
                                                    type="text"
                                                    name="acEndUser"
                                                    placeholder=""
                                                    label={t('imeIprezime')}
                                                    isRequired="1"
                                                    nameClass="custom-input-check"
                                                    messageError={t('obaveznoPolje')}
                                                    value={values['acEndUser']}
                                                    onChange={(e) => onChangeFunction(e)}
                                                    validInputValue={validInput.acEndUser}
                                                    setErrorsFunc={setErrorsFunction}
                                                />
                                            </div>
                                            <div className="form-group delivery-form-input-margin">
                                                <FormInput
                                                    type="text"
                                                    name="acEndUserAddress"
                                                    placeholder=""
                                                    label={t('adresaDostave')}
                                                    isRequired="1"
                                                    maxlength="255"
                                                    nameClass="custom-input-check"
                                                    messageError={t('obaveznoPolje')}
                                                    value={values['acEndUserAddress']}
                                                    onChange={(e) => onChangeFunction(e)}
                                                    validInputValue={validInput.acEndUserAddress}
                                                    setErrorsFunc={setErrorsFunction}
                                                />
                                            </div>
                                            <div className="form-group delivery-form-input-margin">
                                                <label htmlFor="acPostCode">{t('grad')}</label>
                                                <Autocomplete
                                                    options={itemsCities}
                                                    onChange={(event, val) => {
                                                        if (val == null) {
                                                            val = ""
                                                        }
                                                        cityChangeFunction(event, val)
                                                    }}
                                                    name="acPostCode"
                                                    onBlur={(e) => validateAutocomplete(e)}
                                                    value={values['acPostCode'] ? values['acPostCode'] : ""}
                                                    renderInput={(params) => (
                                                        <div ref={params.InputProps.ref}>
                                                            <Form.Control
                                                                {...params.inputProps}
                                                                type="text"
                                                                id="acPostCode"
                                                                name="acPostCode"
                                                                isInvalid={validInput.acPostCode == 1}
                                                                isValid={validInput.acPostCode == 2}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                                {validInput.acPostCode == 1 &&
                                                    <div className="invalid-feedback invalid-feedback-absolute1 d-block">
                                                        {t('obaveznoPolje')}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group delivery-form-input-margin">
                                                <FormInput
                                                    type="text"
                                                    name="acPhone1"
                                                    placeholder=""
                                                    label={t('telefon')}
                                                    isRequired="1"
                                                    nameClass="custom-input-check"
                                                    messageError={t('obaveznoPolje')}
                                                    value={values['acPhone1']}
                                                    onChange={(e) => onChangeFunction(e)}
                                                    validInputValue={validInput.acPhone1}
                                                    setErrorsFunc={setErrorsFunction}
                                                />
                                            </div>
                                            <div className="form-group delivery-form-input-margin">
                                                <label htmlFor="acPhone2">{t('tel2')}</label>
                                                <input type="number" className="form-control " id="acPhone2" name="acPhone2" onChange={(e) => onChangeFunction(e)} value={values['acPhone2']} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="payment-more-option-content">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="info-payment-address">
                                            <div className="info-payment-address-content">
                                                <div className="delivery-form-name-payment">
                                                    {t('napNaAdr')}
                                                </div>
                                                <div className="delivery-form-buttons-payment">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="isRansomDelivery"
                                                            id="inlineRadio1"
                                                            value={1}
                                                            checked={values.isRansomDelivery == 1}
                                                            onChange={(e) => onChangeFunction(e)}
                                                        />
                                                        <label className="form-check-label" htmlFor="inlineRadio1">{t('da')}</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="isRansomDelivery"
                                                            id="inlineRadio2"
                                                            value={0}
                                                            checked={values.isRansomDelivery == 0}
                                                            onChange={(e) => onChangeFunction(e)}
                                                        />
                                                        <label className="form-check-label" htmlFor="inlineRadio2">{t('ne')}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="check-option-payment">
                                            <div className="check-option-payment-info">{t('cekovi')} <input type="checkbox" name="acPayMethod" value={false} onChange={(e) => onChangeFunction(e)} disabled={values.isRansomDelivery == 0 ? true : false} checked={values.acPayMethod == true} /></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="options-payment">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group delivery-form-input-margin">
                                                        <FormInput
                                                            type="number"
                                                            name="anRansomAmount"
                                                            placeholder=""
                                                            label={t('iznosOtkupa')}
                                                            isRequired="1"
                                                            nameClass="custom-input-check"
                                                            messageError={t('obaveznoPolje')}
                                                            value={values['anRansomAmount']}
                                                            onChange={(e) => onChangeFunction(e)}
                                                            validInputValue={validInput.anRansomAmount}
                                                            setErrorsFunc={setErrorsFunction}
                                                            disable={values.isRansomDelivery == 0 ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group delivery-form-input-margin">
                                                        <FormInput
                                                            type="select"
                                                            name="acCompanyBankAccount"
                                                            label={t('brRacZaUplatu')}
                                                            isRequired="1"
                                                            nameClass=""
                                                            messageError={t('obaveznoPolje')}
                                                            options={bankAccountItems}
                                                            value={bankOptions.value == "novi racun" ? "novi racun" : values['acCompanyBankAccount']}
                                                            onChange={(e) => {
                                                                if (e.target.value == "novi racun") {
                                                                    setBankOptions({ value: "novi racun" })
                                                                    onChangeFunction(e)
                                                                } else {
                                                                    setBankOptions({ value: "" })
                                                                    onChangeFunction(e)
                                                                }
                                                            }}
                                                            validInputValue={validInput.acCompanyBankAccount}
                                                            setErrorsFunc={setErrorsFunction}
                                                            disable={values.isRansomDelivery == 0 ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group delivery-form-input-margin">
                                                        <FormInput
                                                            type="text"
                                                            name="acCompanyReceipt"
                                                            placeholder=""
                                                            label={t('brIseckaIliFak')}
                                                            isRequired="1"
                                                            nameClass="custom-input-check"
                                                            messageError={t('obaveznoPolje')}
                                                            value={values['acCompanyReceipt']}
                                                            onChange={(e) => onChangeFunction(e)}
                                                            validInputValue={validInput.acCompanyReceipt}
                                                            setErrorsFunc={setErrorsFunction}
                                                        // disable={values.isRansomDelivery == 0 ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                                {bankOptions.value == "novi racun" &&
                                                    <div className="col-sm-6">
                                                        <div className="form-group delivery-form-input-margin">
                                                            <FormInput
                                                                type="number"
                                                                name="acCompanyBankAccount"
                                                                label={t('noviBrRac')}
                                                                placeholder={t('unesiiteBrRac')}
                                                                isRequired="1"
                                                                nameClass="custom-input-check"
                                                                messageError={t('obaveznoPolje')}
                                                                value={values['acCompanyBankAccount']}
                                                                onChange={(e) => {
                                                                    if (bankOptions.value == "novi racun") {
                                                                        onChangeFunction(e)
                                                                    }
                                                                }}
                                                                validInputValue={validInput.acCompanyBankAccount}
                                                                setErrorsFunc={setErrorsFunction}
                                                                disable={values.isRansomDelivery == 0 ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="delivery-file-att-content">
                                        <div className="delivery-file-att-item">
                                            {t('skenIsecakIliFaktura')}
                                            <label>
                                                <span className="delivery-label">{t('okaciSliku')}<i className="fas fa-file-upload"></i></span>
                                                <input type="file" name="acDocument1" onChange={handleFileInput} onClick={resetFileValue} />
                                            </label>
                                            {imgName1 != "" && <span className="delivery-img-name">{imgName1.name}</span>}
                                            {validInput.acDocument1 == 1 &&
                                                <div className="invalid-feedback d-block">
                                                    {t('priloziteDok')}
                                                </div>
                                            }
                                            {/* {values.acDocument1 && (
                                                <div className="delivery-img-preview mx-auto">
                                                    <img alt="document not found" className="img-fluid" src={values.acDocument1} />
                                                </div>
                                            )} */}
                                        </div>
                                        <div className="delivery-file-att-item">
                                            {t('eweOtp')}
                                            <label>
                                                <span className="delivery-label">{t('okaciSliku')}<i className="fas fa-file-upload"></i></span>
                                                <input type="file" name="acDocument2" onChange={handleFileInput} onClick={resetFileValue} />
                                            </label>
                                            {imgName2 != "" && <span className="delivery-img-name">{imgName2.name}</span>}
                                            {validInput.acDocument2 == 1 &&
                                                <div className="invalid-feedback d-block">
                                                    {t('priloziteDok')}
                                                </div>
                                            }
                                            {/* {values.acDocument2 && (
                                                <div className="delivery-img-preview mx-auto">
                                                    <img alt="document not found" className="img-fluid" src={values.acDocument2} />
                                                </div>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="delivery-form-submit-button">
                                        <div className="btn-sbmit-delviery" onClick={handleSubmit}>{t('potvrdi')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {show && <ModalDelivery item={values} images={[imgName1, imgName2]} handleClose={handleClose} sendData={sendData} show={show} />
            }
            {showConfirmModal && <ModalConfirm handleClose={handleModalConfirmClose} show={showConfirmModal} functionActionConfirm={functionActionConfirm} />}
        </>
    );
};

export default DeliveryPage;