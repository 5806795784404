import { ActionTypes } from '../contants/action-types';
import axios from 'axios';
import { axiosinstance } from '../../configAxios';
import messages from '../../messages';
import { toast } from 'react-toastify';
import { runLogoutTimer } from '../../services/AuthService';
import MessageInfoBox from '../../components/other/MessageInfoBox';
import SecureLS from 'secure-ls';
import { setItem, clearStore } from '../../indexedDB';
import CryptoJS from 'crypto-js';

let ls = new SecureLS({ encodingType: 'aes' });
export const getB2bSettings = () => async (dispatch) => {
    try {
        const attributes = {};
        const configRes = axiosinstance(attributes, '8223', 'getB2bSettings');

        // console.log('Aplikacija se inicijalizuje...', configRes);
        axios(configRes)
            .then(function (response) {
                // console.log('test', response.data.response);
                if (response.data.response.isOk == 1) {
                    dispatch({
                        type: 'SET_B2B_SETTINGS',
                        payload: response.data.response.siteSettings,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    } catch (error) {

    }

};
export const signin = (email, password) => async (dispatch) => {

    dispatch({
        type: 'USER_SIGNIN_REQUEST',
        payload: { email, password }
    });

    try {
        if (email !== '' && password !== '') {
            let ls = new SecureLS({ encodingType: 'aes' });
            const attributes = {};
            attributes['acEmail'] = email;
            attributes['acPassword'] = password;
            const configRes = axiosinstance(attributes, '8224', 'loginFunction');
            const messagesItems = messages;

            axios(configRes)
                .then(function (response) {
                    if (response.data.isOk == 1) {
                        const items = {};
                        items['homePage'] = false;
                        items['otherPage'] = true;
                        localStorage.setItem('menuSettings', JSON.stringify(items));
                        ls.set('userInfo', response.data.response);
                        // localStorage.setItem('userInfo', JSON.stringify(response.data.response));
                        ls.set('sessionToken', response.data.sessionTokken);
                        // localStorage.setItem('sessionToken', JSON.stringify(response.data.sessionTokken)); // ovo je bilo pre kriptovanja 23.02.2024.
                        localStorage.setItem('isSignoutDispatched', 'false');
                        //nakon logovanja korisnika prvo setujemo default-ne vrednosti za valutu i da li je ukljucen pdv ili ne
                        /*var userInfoData = response.data.response;
                        const attributes3 = {};
                        attributes3['isForLoggedUser'] = 1;
                        attributes3['anRoleId'] = 1;
                        attributes3['anCompanyKey'] = userInfoData.company.anCompanyKey;
                        const configRes = axiosinstance(attributes3, '8224', 'getSetMenu');
    
                        axios(configRes)
                            .then(function (response2) {
                                var activeCurrencyObj = {};
                                response2.data.response.currency.map((item, key) => {
                                    if (item._id == response.data.response.company.department[0].user[0].userSettings[0].isPriceInEur) {
                                        activeCurrencyObj = item;
                                    }
                                });*/

                        var activeCurrencyObj = response.data.response.company.department[0].user[0].userSettings[0].setCurrency;

                        //setujemo aktivnu valutu
                        dispatch({
                            type: 'SET_ACTIVE_CURRENCY',
                            payload: activeCurrencyObj
                        });
                        //setujemo aktivni porez da/ne
                        dispatch({
                            type: 'SET_ACTIVE_VAT',
                            payload: response.data.response.company.department[0].user[0].userSettings[0].isPriceWithVat
                        });
                        //setujemo korisnikov settings sortiraje kataloga default
                        dispatch({
                            type: 'SET_ACTIVE_SORT',
                            payload: response.data.response.company.department[0].user[0].userSettings[0].anCatalogSortingType
                        });
                        //setujemo korisniov settings prikaz kataloga default
                        dispatch({
                            type: 'SET_ACTIVE_VIEW',
                            payload: response.data.response.company.department[0].user[0].userSettings[0].anCatalogViewType
                        });

                        localStorage.setItem('activeCurrency', JSON.stringify(activeCurrencyObj));
                        localStorage.setItem('activeVat', JSON.stringify(response.data.response.company.department[0].user[0].userSettings[0].isPriceWithVat));

                        localStorage.setItem('activeProductView', JSON.stringify(response.data.response.company.department[0].user[0].userSettings[0].anCatalogViewType));
                        localStorage.setItem('activeProductSort', JSON.stringify(response.data.response.company.department[0].user[0].userSettings[0].anCatalogSortingType));
                        /* })
                         .catch(function (error) {
                             console.log(error);
                         });*/
                        //////////////////// kraj ////////////////////////////////

                        //setujemo osnovne podatke za ulogovanog korisnika
                        dispatch({
                            type: 'USER_SIGNIN_SUCCESS',
                            payload: response.data.response
                        });
                        dispatch({
                            type: 'SET_USER_SETTINGS',
                            payload: response.data.response.company.department[0].user[0].userSettings[0]
                        });

                        //setovanje datuma kada treba da se izloguje korisnik
                        var endOfDay = new Date();
                        endOfDay.setHours(23, 59, 59, 999);
                        localStorage.setItem('endOfDay', endOfDay);


                    } else {
                        toast(<MessageInfoBox message={response.data.acMsg} type={response.data.response.isOk} />, {
                            className: 'red-background',
                        });
                        dispatch({
                            type: 'USER_SIGNIN_FAIL',
                            payload: response.data.isOk,
                            message: messagesItems[response.data.acMsg]
                        });
                        // toast(<MessageInfoBox message={response.data.acMsg} type={response.data.isOk} />, {
                        //     className: response.data.isOk == 1 ? 'green-background' : 'red-background',
                        // });
                    }
                })
                .catch(function (error) {
                    console.log(error, 'error');
                    toast(<MessageInfoBox message="globalError" type="0" />, {
                        className: 'red-background',
                    });
                });
        } else {
            toast(<MessageInfoBox message={'errorLoginFail'} type={0} />, {
                className: 'red-background',
            });
        }

    } catch (error) {
        dispatch({
            type: 'USER_SIGNIN_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message :
                error.message
        });
    };

}

export const changeProductSort = (val) => (dispatch) => {
    dispatch({
        type: 'SET_ACTIVE_SORT',
        payload: val
    });
    localStorage.setItem('activeProductSort', JSON.stringify(val));
};

export const changeProductView = (val) => (dispatch) => {
    dispatch({
        type: 'SET_ACTIVE_VIEW',
        payload: val
    });
    localStorage.setItem('activeProductView', JSON.stringify(val));
};

export const signout = (history) => async (dispatch) => {
    // console.group('signout function triggered. Removing the storage...');
    // ls.clear();
    localStorage.removeItem('userInfo');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('compareItems');
    localStorage.removeItem('getUserBanner');
    localStorage.removeItem('getUserNews');
    localStorage.removeItem('getUserPopup');
    localStorage.removeItem('getUserSettings');
    localStorage.removeItem('activeCurrency');
    localStorage.removeItem('getActiveCartFunction');
    localStorage.removeItem('getCartListFunction');
    localStorage.removeItem('getCartItemsList');
    localStorage.removeItem('activeProductSort');
    localStorage.removeItem('activeProductView');
    localStorage.removeItem('activeVat');
    localStorage.removeItem('customOffer');
    localStorage.removeItem('priceCatalog');
    localStorage.removeItem('endOfDay');
    localStorage.removeItem('userSurveyValues');
    localStorage.removeItem('getUserSurvey');
    localStorage.removeItem('sessionToken');
    // localStorage.removeItem('catalogItems');
    // console.log('Storage successfuly removed! Dispatching "USER_SIGNOUT" action...');

    // Cookies.remove('AuthToken');
    dispatch({
        type: 'USER_SIGNOUT'
    });
    await clearStore();
    history.push('/');
    // console.log('Dispatch "USER_SIGNOUT" action successfuly finished.');
    // console.log('Ending signout function.');
    // console.groupEnd();
};

export const menuMobileShow = (setMenu) => async (dispatch) => {
    dispatch({
        type: 'SET_MENU_MOBILE',
        payload: setMenu
    });
};

export const getUserSettingsFunction = (eurRsdSettings, vatSettings, location) => async dispatch => {
    const arr = {};
    arr['isPriceInEur'] = eurRsdSettings;
    arr['isPriceWithVat'] = vatSettings;
    localStorage.setItem('getUserSettings', JSON.stringify(arr));

    var pathname = location.pathname;

    dispatch({
        type: 'SET_USER_SETTINGS',
        payload: arr
    });

    if (pathname.includes('katalog') || pathname.includes('каталог')) {

        var pathnameSplit = pathname.split('/');
        var catagoryType = pathnameSplit[2];
        var categoryId = pathnameSplit[3];
        var anId = 0;

        try {

            var userInfoData = ls.get('userInfo');
            var getUserSettings = JSON.parse(localStorage.getItem('getUserSettings'));

            const attributes = {};
            attributes['anMainCategoryId'] = catagoryType == 'kategorija' ? categoryId : 0;
            attributes['anCategoryId'] = catagoryType == 'podkategorija' ? categoryId : 0;
            attributes['anSubCategoryId'] = catagoryType == 'podpodkategorija' ? categoryId : 0;
            attributes['acBrand'] = catagoryType == 'brend' ? categoryId : '';
            attributes['anId'] = anId.trim() !== "" && anId !== undefined && anId !== null ? anId : 0;
            attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
            attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
            attributes['isPdv'] = vatSettings;
            attributes['isEur'] = eurRsdSettings;
            attributes['anCatalogSortingType'] = 1;

            const configRes = axiosinstance(attributes, '8224', 'getProduct');

            axios(configRes)
                .then(function (response) {
                    if (response.data.isOk == 1) {
                        dispatch({
                            type: 'SET_CATALOG_ITEMS',
                            payload: response.data.response.product
                        });
                        dispatch({
                            type: 'SET_FILTERS',
                            payload: response.data.response.tempFilterList == null ? [] : response.data.response.tempFilterList
                        });
                        dispatch({
                            type: 'SET_FILTERS_BRANDS',
                            payload: response.data.response.brandList == null ? [] : response.data.response.brandList
                        });
                    }
                })
                .catch(function (error) {
                    dispatch({ type: 'SET_CATALOG_ITEMS', payload: [] });
                    console.log(error);
                });

        } catch (error) {

        }
    }
};

export const SetVatFunction = (val, location, acFilterTable, acIdents) => async (dispatch) => {
    dispatch({
        type: 'SET_ACTIVE_VAT',
        payload: val
    });
    localStorage.setItem('activeVat', JSON.stringify(val));


    //pozivano funkciju za ucitavanje i setovanje kataloga i filtera
    getCatalogList(location, dispatch, acFilterTable, acIdents);

    //pozivamo funkciju za proizvod detaljno
    getProductFunction(location, dispatch);

    //pozivano funkciju za ucitavanje i setovanje korpe
    getCartList(dispatch);
};

export const SetCurrencyFunction = (item, location, acFilterTable, history, acIdents) => async (dispatch) => {

    dispatch({
        type: 'SET_ACTIVE_CURRENCY',
        payload: item
    });
    localStorage.setItem('activeCurrency', JSON.stringify(item));

    // if (location.search) {
    //     var filtersList = location.search.split('?')[1].split('&');
    //     var filtered = filtersList.filter(function (value, index, arr) {
    //         return !value.includes('cena');
    //     });
    //     filtered = filtered.join('&');
    //     history.push('?' + filtered);
    // } else {
    //     // pozivano funkciju za ucitavanje i setovanje kataloga i filtera
    //     getCatalogList(location, dispatch, acFilterTable);
    // }

    if (!location.pathname.includes("pretraga")) {
        getCatalogList(location, dispatch, acFilterTable, acIdents);
    }

    //pozivamo funkciju za proizvod detaljno
    getProductFunction(location, dispatch);

    //pozivano funkciju za ucitavanje i setovanje korpe
    getCartList(dispatch);
};

function getCartList(dispatch) {

    var userInfoData = ls.get('userInfo');

    const attributes2 = {};
    attributes2['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes2['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : '0';
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
    attributes2['anCurrency'] = activeCurrency._id;
    const configRes2 = axiosinstance(attributes2, '8224', 'getSetCart');

    axios(configRes2)
        .then(function (response) {
            if (response.data.isOk == 1) {
                var activeCart = response.data.response.cartList.filter(x => x.isActive == 1)
                var activeCartItems = activeCart[0].cartItemsList;
                dispatch({
                    type: 'SET_CART_LIST',
                    payload: response.data.response.cartList
                });
                dispatch({
                    type: 'SET_ACTIVE_CART',
                    payload: response.data.response.cartList.filter(x => x.isActive == 1)
                });
                dispatch({
                    type: 'SET_CART_ITEMS_LIST',
                    payload: activeCartItems
                });
                ls.set('getCartListFunction', response.data.response.cartList);
                ls.set('getActiveCartFunction', response.data.response.cartList.filter(x => x.isActive == 1));
                ls.set('getCartItemsList', activeCartItems);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

function getProductFunction(location, dispatch) {
    var pathname = location.pathname;
    var anId = pathname.split("/")[2];

    if ((location.pathname.includes('proizvod') || location.pathname.includes('производ')) && !location.pathname.includes('uporedi-proizvode')) {
        try {

            var userInfoData = ls.get('userInfo');
            var getUserSettings = JSON.parse(localStorage.getItem('getUserSettings'));
            var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
            var activeVat = JSON.parse(localStorage.getItem('activeVat'));
            // console.log(userInfoData, 'userInfoData')
            const attributes = {};
            attributes['anMainCategoryId'] = 0;
            attributes['anCategoryId'] = 0;
            attributes['anSubCategoryId'] = 0;
            attributes['acBrand'] = '';
            attributes['anId'] = anId && !isNaN(anId) && anId.trim() !== "" && anId !== undefined && anId !== null ? Number(anId) : 0;
            attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
            attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
            attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
            attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
            attributes['anCatalogSortingType'] = 1;
            attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
            const configRes = axiosinstance(attributes, '8224', 'getProduct');


            axios(configRes)
                .then(function (response) {
                    if (response.data.isOk == 1) {
                        // console.log(response.data.response.product[0].anSubCategoryKey);
                        let cat = response.data.response.product[0].anSubCategoryKey;
                        attributes["anSubCategoryId"] = cat;
                        attributes["anId"] = 0;
                        // console.log(attributes, 'attributes123')
                        const configRes = axiosinstance(attributes, "8224", "getProduct");

                        axios(configRes)
                            .then(function (response) {

                                // console.log(response.data.response.product, 'ssssss');
                                if (response.data.isOk == 1) {
                                    let filterIt = response.data.response.product.filter((item) => {
                                        if (item.anProductId != anId) return true;
                                    });

                                    dispatch({
                                        type: "SET_RELATED_CATEGORY_ITEMS",
                                        payload: filterIt,
                                    });
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });


                        dispatch({
                            type: 'SET_PRODUCT_DETAIL',
                            payload: response.data.response.product
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    // console.log("udje u error")
                });

        } catch (error) {

        }
    }
}

function getCatalogList(location, dispatch, acFilterTable, acIdents) {
    dispatch({ type: 'SET_CATALOG_ITEMS_REQUEST' }); // Postavite loading na true
    var pathname = location.pathname;

    if (pathname.includes('katalog') || pathname.includes('kreiraj') || pathname.includes('каталог')) {
        if (!pathname.includes('specijalna')) {
            var anPriceMin = 0;
            var anPriceMax = 10000000;
            var pathnameSplit = pathname.split('/');
            var catagoryType = pathnameSplit[2];
            var categoryId = pathnameSplit[3];
            var anId = 0;

            try {
                var filtersObject = acFilterTable.split('&');
                var filtersArray = [];
                var brandItem = '';
                if (acFilterTable != '') {
                    filtersObject.forEach(item => {
                        var filtersObjectItem = item.split('=');

                        if (filtersObjectItem[0] == 'cena_0') {
                            anPriceMin = filtersObjectItem[1].split('_')[0];
                            anPriceMax = filtersObjectItem[1].split('_')[1];
                        } else {
                            var obj = {};
                            obj['acAttributeURLName'] = filtersObjectItem[0].split('_')[0];
                            obj['acUrlValue'] = filtersObjectItem[1].split('_')[0];
                            obj['anAttribute'] = filtersObjectItem[0].split('_')[1];
                            obj['anValue'] = filtersObjectItem[1].split('_')[1];

                            filtersArray.push(obj);
                        }
                    });
                }

                var userInfoData = ls.get('userInfo');
                var activeVat = JSON.parse(localStorage.getItem('activeVat'));
                var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));
                var activeProductSort = JSON.parse(localStorage.getItem('activeProductSort'));

                const attributes = {};
                var webFiltersObj = {};
                var isLastValue = 0;

                filtersArray.forEach((item, index, array) => {

                    if (index === array.length - 1) {
                        isLastValue = 1;
                    }

                    if (!webFiltersObj[item.anAttribute]) {

                        webFiltersObj[item.anAttribute] = {};
                        webFiltersObj[item.anAttribute]["anGroup"] = item.anAttribute;
                        webFiltersObj[item.anAttribute]["webFilterList"] = [];
                        webFiltersObj[item.anAttribute]["webFilterList"].push({
                            anAttribute: item.anAttribute,
                            anValue: item.anValue,
                            isLast: isLastValue
                        });
                    } else {
                        webFiltersObj[item.anAttribute]["webFilterList"].push({
                            anAttribute: item.anAttribute,
                            anValue: item.anValue,
                            isLast: isLastValue
                        });
                    }
                });

                attributes['anCategoryId'] = acIdents != "" ? 0 : catagoryType == 'podkategorija' ? categoryId : 0;
                attributes['anSubCategoryId'] = acIdents != "" ? 0 : catagoryType == 'podpodkategorija' ? categoryId : 0;
                attributes['acRebate'] = userInfoData ? userInfoData.company.acCompanyRebate : 'E';
                attributes['isPdv'] = activeVat == 1 || activeVat == 0 ? activeVat : 1;
                attributes['isEur'] = activeCurrency ? activeCurrency._id : 0;
                // attributes['acBrand'] = brandItem;
                attributes['acFilterTable'] = acIdents != "" ? [] : Object.values(webFiltersObj);
                attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
                attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 2061;
                attributes['anCatalogSortingType'] = activeProductSort ? activeProductSort : 1;

                attributes['anPriceMin'] = anPriceMin;
                attributes['anPriceMax'] = anPriceMax;
                attributes['acIdents'] = acIdents != "" ? acIdents.toString() : '';

                const configRes = axiosinstance(attributes, '8224', 'filterProduct');

                axios(configRes)
                    .then(async function (response) {
                        localStorage.setItem("priceCatalog", JSON.stringify({
                            anPriceFilterMax: response.data.response.anPriceFilterMax,
                            anPriceFilterMin: response.data.response.anPriceFilterMin,
                        }));
                        dispatch({
                            type: 'SET_CATALOG_ITEMS',
                            payload: response.data.response.product
                        });
                        dispatch({
                            type: 'SET_FILTERS',
                            payload: response.data.response.tempFilterList == null ? [] : response.data.response.tempFilterList
                        });
                        dispatch({
                            type: 'SET_FILTERS_BRANDS',
                            payload: response.data.response.brandList == null ? [] : response.data.response.brandList
                        });

                        dispatch({
                            type: 'SET_FILTERS_PRICE',
                            payload: {
                                anPriceFilterMax: response.data.response.anPriceFilterMax,
                                anPriceFilterMin: response.data.response.anPriceFilterMin,
                            }
                        });
                        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(response.data.response.product), 'Č1k1n@T@jn@').toString();
                        await setItem('catalogItems', encryptedData);
                        // ls.set('catalogItems', response.data.response.product);
                    })
                    .catch(function (error) {
                        console.log(error);
                        // dispatch({ type: 'SET_CATALOG_ITEMS', payload: [] });
                    });

            } catch (error) {

            }
        }
    };
}

export const editUserFunction = (values, data) => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');
    var activeCurrency = JSON.parse(localStorage.getItem('activeCurrency'));

    const attributes = {};

    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['acFirstName'] = values.acFirstName;
    attributes['acLastName'] = values.acLastName;
    attributes['acJobPosition'] = values.acJobPosition;
    attributes['acPassword'] = values.newPassword;

    //ako je data prazno, u pitanju je promena informacija o ulogovanom korisniku u suprotnom je menjanje korisnika iz admin taba
    if (data == "") {
        attributes['anDepartmentKey'] = userInfoData ? userInfoData.company.department[0].anDepartmentKey : 0;
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        attributes['isActive'] = userInfoData ? userInfoData.company.department[0].user[0].isActive : 0;
        attributes['anRoleKey'] = userInfoData ? userInfoData.company.department[0].user[0].anRoleKey : 0;
        attributes['isPriceWithVat'] = values.priceVat;
        attributes['isPriceInEur'] = activeCurrency._id;
        attributes['anCatalogViewType'] = values.anCatalogViewType;
        attributes['anCatalogSortingType'] = values.anCatalogSortingType;
        attributes['anMargin'] = values.anMargin;
        attributes['anMarkUp'] = values.anMarkUp;
    } else {
        attributes['anDepartmentKey'] = values.anDepartmentKey;
        attributes['anUserKey'] = data.anUserKey;
        //kada se radi edit korisinka, values.isActive je undefined jer se ta informacija salje prilikom check-a aktivan/neaktivan korisnik, ne prilikom izmene korisnika
        attributes['isActive'] = values.isActive != undefined ? values.isActive : data.isActive;
        attributes['anRoleKey'] = values.anRoleKey;
        attributes['isPriceWithVat'] = 0;
        attributes['isPriceInEur'] = 0;
        attributes['anCatalogViewType'] = 1;
        attributes['anCatalogSortingType'] = 1;
        attributes['anMargin'] = 0;
        attributes['anMarkUp'] = 0;
    }

    const configRes = axiosinstance(attributes, '8224', 'editUser');

    axios(configRes)
        .then(function (response) {
            toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
            });
            userInfoData.company.department[0].user[0] = response.data.response.response;
            if (data == "") {
                userInfoData.company.department[0].user[0].userSettings[0].anMarkUp = attributes['anMarkUp'];
                userInfoData.company.department[0].user[0].userSettings[0].anMargin = attributes['anMargin'];
                // userInfoData.company.department[0].user[0].acFirstName = response.data.response.response.acFirstName;
                // userInfoData.company.department[0].user[0].acLastName = response.data.response.response.acLastName;
                ls.set('userInfo', userInfoData);
                // localStorage.setItem('userInfo', JSON.stringify(userInfoData));
            }
            dispatch(editCompanyUserList())
        })
        .catch(function (error) {
            console.log(error, 'error');
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};

export const editCompanyMailFunction = (value) => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');
    const attributes = {};

    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['acEmail'] = value.acEmail;
    attributes['acType'] = value.acType;

    const configRes = axiosinstance(attributes, '8224', 'editCompanyEmail');

    axios(configRes)
        .then(function (response) {
            if (attributes['acEmail'] != "") {
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });
            } else {
                userInfoData.company.email = response.data.response.response.company.email;
                ls.set('userInfo', userInfoData);
                // localStorage.setItem('userInfo', JSON.stringify(userInfoData));
            }
        })
        .catch(function (error) {
            console.log(error, 'error');
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};

export const userVoucherList = () => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');
    const attributes = {};
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;

    const configRes = axiosinstance(attributes, '8224', 'postVoucherList');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_VOUCHER_ITEMS',
                payload: response.data.response.promoCodeList
            });
        })
        .catch(function (error) {
            console.log(error, 'error');
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};

export const editCompanyFunction = (values) => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');
    const attributes = {};

    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['acCompanyLogo'] = values.acCompanyLogo;
    attributes['acCompanyMemoHeader'] = values.acCompanyMemoHeader;
    attributes['acCompanyMemoFooter'] = values.acCompanyMemoFooter;

    const configRes = axiosinstance(attributes, '8224', 'editCompanyData');

    axios(configRes)
        .then(function (response) {
            toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
            });
            userInfoData.company.acCompanyLogo = attributes['acCompanyLogo'];
            userInfoData.company.acCompanyMemoHeader = attributes['acCompanyMemoHeader'];
            userInfoData.company.acCompanyMemoFooter = attributes['acCompanyMemoFooter'];
            ls.set('userInfo', userInfoData);
            // localStorage.setItem('userInfo', JSON.stringify(userInfoData));
        })
        .catch(function (error) {
            console.log(error, 'error');
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};

export const editCompanyUserList = () => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');
    const attributes = {};

    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;

    const configRes = axiosinstance(attributes, '8224', 'postUserList');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_COMPANY_USERS_LIST',
                payload: response.data.response.tudList
            });
        })
        .catch(function (error) {
            console.log(error, 'error');
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};

export const getCompanyDepartment = () => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');
    const attributes = {};

    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;

    const configRes = axiosinstance(attributes, '8224', 'postSetDepartment');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_COMPANY_DEPARTMENT_LIST',
                payload: response.data.response.departmentList
            });
        })
        .catch(function (error) {
            console.log(error, 'error');
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};

export const getRoles = () => async (dispatch, state) => {

    const attributes = {};
    const configRes = axiosinstance(attributes, '8224', 'postSetRole');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_ROLES_LIST',
                payload: response.data.response.roleList
            });
        })
        .catch(function (error) {
            console.log(error, 'error');
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};

export const addNewUser = (values) => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');
    const attributes = {};

    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
    attributes['anDepartmentKey'] = values.anDepartmentKey;
    attributes['acFirstName'] = values.acFirstName;
    attributes['acLastName'] = values.acLastName;
    attributes['acEmail'] = values.acEmail;
    attributes['acJobPosition'] = values.acJobPosition;
    attributes['isActive'] = values.isActive;
    attributes['acPassword'] = values.acPassword;
    attributes['anRoleKey'] = values.anRoleKey;

    const configRes = axiosinstance(attributes, '8224', 'insertNewUser');

    axios(configRes)
        .then(function (response) {
            if (response.data.response.isOk == 1) {
                axios({
                    method: "POST",
                    url: "https://newb2b.ewe.rs:5004/addNewUser",
                    data: {
                        email: values.acEmail,
                        password: values.acPassword
                    }
                })
            }
            toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
            });
            dispatch(editCompanyUserList())
        })
        .catch(function (error) {
            console.log(error, 'error');
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};

export const getSaleTarget = () => async (dispatch, state) => {

    var userInfoData = ls.get('userInfo');

    const attributes = {};
    attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;

    const configRes = axiosinstance(attributes, '8224', 'postCompanySaleTarget');

    axios(configRes)
        .then(function (response) {
            dispatch({
                type: 'SET_SALE_TARGET_LIST',
                payload: response.data.response.companySaleTargetList
            });
        })
        .catch(function (error) {
            console.log(error, 'error');
            toast(<MessageInfoBox message="globalError" type="0" />, {
                className: 'red-background',
            });
        });
};

export const postInsertCoinUser = (values) => {
    return (dispatch, state) => {

        var userInfoData = ls.get('userInfo');

        const attributes = {};

        attributes['anCompanyKey'] = userInfoData ? userInfoData.company.anCompanyKey : 0;
        attributes['anDepartmentKey'] = userInfoData ? userInfoData.company.department[0].anDepartmentKey : 0;
        // attributes['acAvatarUrl'] = values.acAvatarUrl ? values.acAvatarUrl : '';
        attributes['acAvatarUrl'] = "";
        attributes['anActionTypeKey'] = 3;
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        attributes['acFirstName'] = values.acFirstName;
        // attributes['acLastName'] = values.acLastName;
        attributes['acLastName'] = values.acLastName;;


        const configRes = axiosinstance(attributes, '8224', 'postInsertCoinUser');

        return axios(configRes)
            .then(response => {
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });
                // console.log(response.data.response, 'usao u axios > response.data.response')
                return response.data.response;
            })
            .catch(function (error) {
                console.log(error, 'error');
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            });
    }
};


export const newYearRegistration = (values) => {
    return (dispatch, state) => {

        var userInfoData = ls.get('userInfo');

        const attributes = {};

        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        attributes['acName'] = values.acName;
        attributes['acCompany'] = values.acCompany;
        attributes['acEmail'] = values.acEmail;
        attributes['anOption1'] = 0;
        attributes['anOption2'] = 0;
        attributes['anOption3'] = 0;
        attributes['anOption4'] = 0;
        attributes['anOption5'] = 0;
        attributes['anOption6'] = 0;
        attributes['anOption7'] = 0;
        attributes['anOption8'] = 0;
        attributes['acFileName'] = "";
        attributes['acPhone'] = "";
        attributes['acLocation'] = "";
        attributes['acType'] = values.acType;
        attributes['acOption1'] = "";
        attributes['acOption2'] = "";
        attributes['acOption3'] = "";
        attributes['acOption4'] = "";
        attributes['acOption5'] = "";
        const configRes = axiosinstance(attributes, '8224', 'happeningRegistration');

        return axios(configRes)
            .then(response => {
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });

                if (response.data.response.isOk == 1) {
                    axios({
                        method: "POST",
                        url: "https://newb2b.ewe.rs:5000/newYearParty",
                        data: {
                            userEmail: values.acEmail
                        }
                    })
                }
                // console.log(response.data.response, 'usao u axios > response.data.response')
                return response.data.response;
            })
            .catch(function (error) {
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            });
    }
};
export const eweSkiRegistration = (values) => {
    return (dispatch, state) => {

        var userInfoData = ls.get('userInfo');

        const attributes = {};

        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        attributes['acName'] = values.acName;
        attributes['acCompany'] = values.acCompany;
        attributes['acEmail'] = values.acEmail;
        attributes['anOption1'] = values.isBus;
        attributes['anOption2'] = values.isParking;
        attributes['anOption3'] = values.isSkiing;
        attributes['anOption4'] = values.isEquipment;
        attributes['anOption5'] = values.isSpecialFood;
        attributes['anOption6'] = values.isReferalTalk;
        attributes['anOption7'] = 0;
        attributes['anOption8'] = 0;
        attributes['acFileName'] = "";
        attributes['acPhone'] = values.acPhone;
        attributes['acLocation'] = "";
        attributes['acType'] = values.acType;
        attributes['acOption1'] = values.brLk;
        attributes['acOption2'] = values.specialFood ? values.specialFood : "";
        attributes['acOption3'] = values.dateOfBirth ? values.dateOfBirth : "";
        attributes['acOption4'] = values.profileImageUrl ? values.profileImageUrl : "";
        attributes['acOption5'] = "";
        const configRes = axiosinstance(attributes, '8224', 'happeningRegistration');
        // console.log(attributes, 'atr')
        return axios(configRes)
            .then(response => {
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });

                if (response.data.response.isOk == 1) {
                    axios({
                        method: "POST",
                        url: "https://newb2b.ewe.rs:5000/EweSki2023",
                        data: {
                            userEmail: values.acEmail
                        }
                    })
                }
                // console.log(response, 'usao u axios > response.data.response')
                return response.data.response;
            })
            .catch(function (error) {
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            });
    }
};

export const happeningRegistration = (values) => {
    return (dispatch, state) => {

        var userInfoData = ls.get('userInfo');

        const attributes = {};

        // attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
        attributes['acName'] = values.acName;
        attributes['acCompany'] = values.acCompany;
        attributes['acEmail'] = values.acEmail;
        attributes['anOption1'] = 0;
        attributes['anOption2'] = 0;
        attributes['anOption3'] = 0;
        attributes['anOption4'] = 0;
        attributes['anOption5'] = 0;
        attributes['anOption6'] = 0;
        attributes['anOption7'] = 0;
        attributes['anOption8'] = 0;
        attributes['acFileName'] = "";
        attributes['acPhone'] = "";
        attributes['acLocation'] = "";
        attributes['acType'] = values.acType;
        attributes['acOption1'] = "";
        attributes['acOption2'] = "";
        attributes['acOption3'] = "";
        attributes['acOption4'] = "";
        attributes['acOption5'] = "";


        const configRes = axiosinstance(attributes, '8224', 'happeningRegistration');
        // console.log(attributes, 'atr')
        return axios(configRes)
            .then(response => {
                toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                });

                if (response.data.response.isOk == 1) {
                    axios({
                        method: "POST",
                        url: "https://newb2b.ewe.rs:5000/Tehnovacije2023",
                        data: {
                            userEmail: values.acEmail
                        }
                    })
                }
                // console.log(response, 'usao u axios > response.data.response')
                return response.data.response;
            })
            .catch(function (error) {
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            });
    }
};
