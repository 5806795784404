import React, { useState, useEffect, useMemo, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Input } from "@material-ui/core";
import MomentUtils from '@date-io/moment';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { axiosinstance } from '../configAxios';
import { toast } from 'react-toastify';
import MaterialTable from "material-table";
import Moment from 'react-moment';
import Form from 'react-bootstrap/Form'
import PageHelmet from '../components/helmet/PageHelmet';

import { getReclamations, getBrends, globalStatistic } from '../redux/actions/otherActions';
import { getDeliveryCity } from '../redux/actions/cartActions';
import { getPageSeo } from '../redux/actions/pageActions';

import FormInput from '../components/form/FormInput';
import MessageInfoBox from '../components/other/MessageInfoBox';
import PageBanner from '../components/banner/PageBanner';
import { useTranslation } from 'react-i18next';

const PickReturnPage = () => {
    const dispatch = useDispatch();
    const tableRef = createRef();

    const [brendsOption, setBrendsOption] = useState({ value: "" });
    const [tableData, setTableData] = useState([]);

    const getBrendsItems = useSelector((state) => state.getBrendsItems.items);
    // const deliveryItems = useSelector((state) => state.getReclamationsItems.items);
    const getUserInfo = useSelector((state) => state.userInfo.userInfo.company);
    const deliveryCityList = useSelector((state) => state.getDeliveryCity.items);
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('pickReturnPage');

    const itemsCities = useMemo(
        () =>
            deliveryCityList.map((item) => ({
                id: item._id,
                postCode: item.acPostCode,
                city: item.acCity,
                label: item.acPostCode + ' ' + item.acCity,
            })),
        [deliveryCityList]
    );

    useEffect(() => {
        dispatch(getPageSeo(352)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(getDeliveryCity());
        dispatch(getBrends());
        dispatch(globalStatistic(352, 'PAGE'));
    }, [])

    const [values, setValues] = useState({
        acAddress: getUserInfo.acAddress,
        acPostCode: getUserInfo.acPostCode + " " + getUserInfo.acCity,
        city: getUserInfo.acCity,
        postCode: getUserInfo.acPostCode,
        acContactEmail: getUserInfo.acCompanyMail1,
        acContactPerson: "",
        acContactPhone: getUserInfo.acPhone1,
        anType: 1,
        acProductBrand: "",
        acProductName: "",
        acProductSerial: "",
        acProductInvoice: "",
        adProductInvoiceDate: "",
        acProductDescription: "",
    })

    const [errors, setErrors] = useState({
        acAddress: 0,
        acPostCode: 0,
        acContactEmail: 0,
        acContactPerson: 1,
        acContactPhone: 0,
        acProductBrand: 1,
        acProductName: 1,
        acProductDescription: 1,
    })

    const [validInput, setValidInput] = useState({
        acAddress: 2,
        acPostCode: 2,
        acContactEmail: 2,
        acContactPerson: 0,
        acContactPhone: 2,
        acProductBrand: 0,
        acProductName: 0,
        acProductDescription: 0,
    })

    const onChangeFunction = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
        if (e.target.name == "acProductBrand" && e.target.value == "drugo") {
            setValues({ ...values, acProductBrand: "" })
        }
    }

    const cityChangeFunction = (e, val) => {
        setValues({ ...values, acPostCode: val.label, city: val.city, postCode: val.postCode });
    }

    const validateAutocomplete = (e) => {
        if (!e.target.value) {
            setValidInput({ ...validInput, [e.target.name]: 1 });
            setErrors({ ...errors, [e.target.name]: 1 });
        } else {
            setValidInput({ ...validInput, [e.target.name]: 2 });
            setErrors({ ...errors, [e.target.name]: 0 });
        }
    }

    const setErrorsFunction = (name, val) => {
        setErrors({ ...errors, [name]: val });
    };

    const handleSubmit = (e) => {

        setValidInput({
            ...validInput,
            acAddress: values.acAddress == '' && 1,
            acPostCode: values.acPostCode == '' || values.acPostCode == null && 1,
            acContactEmail: values.acContactEmail == '' && 1,
            acContactPerson: values.acContactPerson == '' && 1,
            acContactPhone: values.acContactPhone == '' && 1,
            acProductBrand: values.acProductBrand == '' && 1,
            acProductName: values.acProductName == '' && 1,
            acProductDescription: values.acProductDescription == '' && 1,
        });

        e.preventDefault();

        if (errors.acAddress == 0 && errors.acPostCode == 0 && errors.acContactEmail == 0 && errors.acContactPerson == 0 && errors.acContactPhone == 0 && errors.acProductBrand == 0 && errors.acProductName == 0 && errors.acProductDescription == 0) {
            setTableData([
                ...tableData,
                {
                    acProductBrand: values.acProductBrand,
                    anType: values.anType,
                    acProductName: values.acProductName,
                    acProductSerial: values.acProductSerial,
                    acProductInvoice: values.acProductInvoice,
                    adProductInvoiceDate: values.adProductInvoiceDate,
                    acProductDescription: values.acProductDescription
                }
            ])

            //resetovanje inputa i provera obaveznih polja
            setValidInput({
                ...validInput,
                acProductBrand: 0,
                acProductName: 0,
                acProductDescription: 0,
            });

            setErrors({
                ...errors,
                acProductBrand: 1,
                acProductName: 1,
                acProductDescription: 1,
            })

            setValues({
                ...values,
                anType: 1,
                acProductBrand: "",
                acProductName: "",
                acProductSerial: "",
                acProductInvoice: "",
                adProductInvoiceDate: "",
                acProductDescription: "",
            });

            setBrendsOption({
                value: ""
            })

        } else {
            toast(<MessageInfoBox message='errorReqiuredFormInputs' type={0} />, {
                className: 'red-background',
            });
        }
    };

    const sendData = () => {
        try {
            const attributes = {};
            attributes['anCompanyKey'] = getUserInfo.anCompanyKey
            attributes['anUserKey'] = getUserInfo.department[0].user[0].anUserKey
            attributes['acCompany'] = getUserInfo.acCompany
            attributes['acAddress'] = values.acAddress
            attributes['acCity'] = values.city
            attributes['anPostCode'] = values.postCode.slice(3)
            attributes['acPhone'] = values.acContactPhone
            attributes['acEmail'] = values.acContactEmail
            attributes['acReclamationTable'] = tableData

            const configRes = axiosinstance(attributes, '8224', 'postReclamationCreate');

            axios(configRes)
                .then(function (response) {
                    if (response.data.isOk == 1) {
                        axios({
                            method: "POST",
                            url: "https://newb2b.ewe.rs:5004/reclamation",
                            data: {
                                values: attributes,
                                contactPerson: values.acContactPerson,
                                clerkResponsible: getUserInfo.clerkResponsible[0].acEmail,
                                acPostCode: values.postCode
                            }
                        })
                        toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                            className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                        });
                        setTableData([])
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        } catch (error) {
            console.log(error)
        }
    }

    const renderDateInput = (props) => (
        <Input
            type="text"
            className="form-control"
            id="adProductInvoiceDate"
            name="adProductInvoiceDate"
            onClick={props.onClick}
            value={props.value}
            onChange={props.onChange}
            placeholder="dd.mm.yyyy"
        />
    );

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-content container-catalog-content pickup-return-page">
                <div className="container-fluid-custom">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="banner-image-on-top-page">
                                <PageBanner categoryType="" categoryId="" pageId="352" />
                            </div>
                            <div className="pickup-return-title">{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('naslov')}</div>
                            {pageSeoData.acSeoShortPageDescription && <p>{pageSeoData.acSeoShortPageDescription}</p>}
                            <div className="pickup-return-desc">{t('postovaniPartneri')}</div>
                            <div className="pickup-return-form">
                                <div className="pickup-return-form-title">{t('podaciOPodnosiocu')}</div>
                                <div className="pickup-return-form-body">
                                    <div className="form-body-holder-pickupreturn">
                                        <div className="form-pickupreturn-holder">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <FormInput
                                                            type="text"
                                                            name="acAddress"
                                                            placeholder=""
                                                            label={t('adresaPreuzimanja')}
                                                            isRequired="1"
                                                            nameClass="custom-input-check"
                                                            messageError={t('obaveznoPolje')}
                                                            value={values['acAddress']}
                                                            onChange={(e) => onChangeFunction(e)}
                                                            validInputValue={validInput.acAddress}
                                                            setErrorsFunc={setErrorsFunction}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="acPostCode">{t('grad')}</label>
                                                        <Autocomplete
                                                            options={itemsCities}
                                                            onChange={(event, val) => {
                                                                if (val == null) {
                                                                    val = ""
                                                                }
                                                                cityChangeFunction(event, val)
                                                            }}
                                                            name="acPostCode"
                                                            onBlur={(e) => validateAutocomplete(e)}
                                                            value={values['acPostCode'] ? values['acPostCode'] : ""}
                                                            renderInput={(params) => (
                                                                <div ref={params.InputProps.ref}>
                                                                    <Form.Control
                                                                        {...params.inputProps}
                                                                        type="text"
                                                                        id="acPostCode"
                                                                        name="acPostCode"
                                                                        isInvalid={validInput.acPostCode == 1}
                                                                        isValid={validInput.acPostCode == 2}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                        {validInput.acPostCode == 1 &&
                                                            <div className="invalid-feedback d-block">
                                                                {t('obaveznoPolje')}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <FormInput
                                                            type="email"
                                                            name="acContactEmail"
                                                            placeholder=""
                                                            label={t('kontaktEmail')}
                                                            isRequired="1"
                                                            nameClass="custom-input-check"
                                                            messageError={t('emailNijeDobar')}
                                                            value={values['acContactEmail']}
                                                            onChange={(e) => onChangeFunction(e)}
                                                            validInputValue={validInput.acContactEmail}
                                                            setErrorsFunc={setErrorsFunction}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <FormInput
                                                            type="text"
                                                            name="acContactPerson"
                                                            placeholder=""
                                                            label={t('kontaktOsoba')}
                                                            isRequired="1"
                                                            nameClass="custom-input-check"
                                                            messageError={t('obaveznoPolje')}
                                                            value={values['acContactPerson']}
                                                            onChange={(e) => onChangeFunction(e)}
                                                            validInputValue={validInput.acContactPerson}
                                                            setErrorsFunc={setErrorsFunction}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <FormInput
                                                            type="text"
                                                            name="acContactPhone"
                                                            placeholder=""
                                                            label={t('kotnaktTelefon')}
                                                            isRequired="1"
                                                            nameClass="custom-input-check"
                                                            messageError={t('obaveznoPolje')}
                                                            value={values['acContactPhone']}
                                                            onChange={(e) => onChangeFunction(e)}
                                                            validInputValue={validInput.acContactPhone}
                                                            setErrorsFunc={setErrorsFunction}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pickup-return-form-title">{t('podaciOUredaju')}</div>
                                <div className="pickup-return-form-body-article">
                                    <div className="form-body-holder-pickupreturn-article">
                                        <div className="form-pickupreturn-holder-article">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="pickup-return-form-item">
                                                        <div className="pickup-return-form-name">
                                                            {t('tipKvara')}
                                                        </div>
                                                        <div className="pickup-return-form-buttons">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="anType"
                                                                    id="inlineRadio1"
                                                                    defaultValue={1}
                                                                    defaultChecked={values.anType == 1}
                                                                    onClick={(e) => setValues({ ...values, anType: 1 })}
                                                                />
                                                                <label className="form-check-label" htmlFor="inlineRadio1">{t('doa')}</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="anType"
                                                                    id="inlineRadio2"
                                                                    defaultValue={2}
                                                                    onClick={(e) => setValues({ ...values, anType: 2 })}
                                                                />
                                                                <label className="form-check-label" htmlFor="inlineRadio2">{t('popravkaUredjaja')}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <FormInput
                                                            type="select"
                                                            name="acProductBrand"
                                                            label={t('brend')}
                                                            isRequired="1"
                                                            nameClass=""
                                                            messageError={t('obaveznoPolje')}
                                                            options={getBrendsItems}
                                                            value={brendsOption.value == "drugo" ? t('drugo') : values['acProductBrand']}
                                                            onChange={(e) => {
                                                                if (e.target.value == "drugo") {
                                                                    setBrendsOption({ value: "drugo" })
                                                                    onChangeFunction(e)
                                                                } else {
                                                                    setBrendsOption({ value: "" })
                                                                    onChangeFunction(e)
                                                                }
                                                            }}
                                                            validInputValue={validInput.acProductBrand}
                                                            setErrorsFunc={setErrorsFunction}
                                                        />
                                                        {brendsOption.value == "drugo" &&
                                                            <FormInput
                                                                type="text"
                                                                name="acProductBrand"
                                                                placeholder={t('nazivBrenda')}
                                                                isRequired="1"
                                                                nameClass="custom-input-check"
                                                                messageError={t('obaveznoPolje')}
                                                                value={values['acProductBrand']}
                                                                onChange={(e) => {
                                                                    if (brendsOption.value == "drugo") {
                                                                        onChangeFunction(e)
                                                                    }
                                                                }}
                                                                validInputValue={validInput.acProductBrand}
                                                                setErrorsFunc={setErrorsFunction}
                                                            />
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <FormInput
                                                            type="text"
                                                            name="acProductName"
                                                            placeholder=""
                                                            label={t('nazivIliSifra')}
                                                            isRequired="1"
                                                            nameClass="custom-input-check"
                                                            messageError={t('obaveznoPolje')}
                                                            value={values['acProductName']}
                                                            onChange={(e) => onChangeFunction(e)}
                                                            validInputValue={validInput.acProductName}
                                                            setErrorsFunc={setErrorsFunction}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="acProductSerial">{t('SBuredjaja')}</label>
                                                        <input type="text" className="form-control " id="acProductSerial" name="acProductSerial" onChange={(e) => onChangeFunction(e)} value={values['acProductSerial']} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="acProductInvoice">{t('brFakture')}</label>
                                                        <input type="text" className="form-control " id="acProductInvoice" name="acProductInvoice" onChange={(e) => onChangeFunction(e)} value={values['acProductInvoice']} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">

                                                    {brendsOption.value == "drugo" ?
                                                        <div className="form-group pickup-return-import-decs">
                                                            <small>
                                                                {t('pasus')}
                                                            </small>
                                                        </div>
                                                        :
                                                        getBrendsItems.filter(item => item.acName == values.acProductBrand && item.acImportDescription).map(brend => (
                                                            <div className="form-group pickup-return-import-decs">
                                                                <small>{brend.acImportDescription}</small>
                                                            </div>
                                                        ))
                                                    }
                                                    <div className="form-group">
                                                        <label htmlFor="adProductInvoiceDate">{t('datumProdajeKK')}</label>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <DatePicker clearLabel="poništi" clearable cancelLabel="odustani" value={values['adProductInvoiceDate'] == "" ? null : values['adProductInvoiceDate']}
                                                                onChange={(event) => {
                                                                    if (event != "") {
                                                                        event = event.toISOString()
                                                                    }
                                                                    setValues({ ...values, adProductInvoiceDate: event })
                                                                }}
                                                                TextFieldComponent={renderDateInput}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                }}
                                                                format="DD.MM.YYYY"
                                                                name="adProductInvoiceDate"
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                    <div className="form-group">
                                                        <FormInput
                                                            type="textarea"
                                                            name="acProductDescription"
                                                            label={t('opisKvaraLabel')}
                                                            isRequired="1"
                                                            messageError={t('obaveznoPolje')}
                                                            value={values['acProductDescription']}
                                                            onChange={(e) => onChangeFunction(e)}
                                                            validInputValue={validInput.acProductDescription}
                                                            setErrorsFunc={setErrorsFunction}
                                                        />
                                                    </div>
                                                    <div className="button-add-holder-article">
                                                        <div className="btn-add-article-pickupreturn" onClick={handleSubmit}>{t('dodajUredjaj')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pickup-return-table">
                                    {tableData != "" &&
                                        <>
                                            <MaterialTable
                                                localization={{
                                                    header: {
                                                        actions: ''
                                                    },
                                                    body: {
                                                        editRow: {
                                                            deleteText: t('brisanjeReklamacije'),
                                                            cancelTooltip: t('ne'),
                                                            saveTooltip: t('da')
                                                        }
                                                    }
                                                }}
                                                data={tableData.map(item => Object.assign({}, item))}
                                                tableRef={tableRef}
                                                columns={[
                                                    {
                                                        title: t('brend1'),
                                                        field: "acProductBrand",
                                                        cellStyle: {
                                                            whiteSpace: "nowrap",
                                                        },
                                                    },
                                                    {
                                                        title: t('tip'),
                                                        field: "anType",
                                                        render: item => {
                                                            return (
                                                                item.anType == 1 ? t("doa") :
                                                                    t('popravkaUredjaja')
                                                            )
                                                        },
                                                        cellStyle: {
                                                            whiteSpace: "nowrap",
                                                        },
                                                    },
                                                    {
                                                        title: t('naizvUredjaja'),
                                                        field: "acProductName",
                                                        cellStyle: {
                                                            wordBreak: "break-word",
                                                            minWidth: "300px",
                                                            // width: "300px"
                                                        }
                                                    },
                                                    {
                                                        title: t('serisjkiBr'),
                                                        field: "acProductSerial",
                                                        cellStyle: {
                                                            whiteSpace: "nowrap",
                                                        },
                                                    },
                                                    {
                                                        title: t('brFakture1'),
                                                        field: "acProductInvoice",
                                                        cellStyle: {
                                                            whiteSpace: "nowrap",
                                                        },
                                                    },
                                                    {
                                                        title: t("datum"),
                                                        type: 'date',
                                                        field: "adProductInvoiceDate",
                                                        render: item => {
                                                            return (
                                                                item.adProductInvoiceDate == "" ? "" :
                                                                    <>
                                                                        <Moment format="DD.MM.YYYY.">{item.adProductInvoiceDate}</Moment>
                                                                    </>
                                                            )
                                                        }
                                                    },
                                                    {
                                                        title: t('opisKvara'),
                                                        field: "acProductDescription",
                                                        cellStyle: {
                                                            wordBreak: "break-word",
                                                            minWidth: "500px",
                                                            // width: "500px"
                                                        }
                                                    },
                                                ]}
                                                editable={{
                                                    onRowDelete: oldData =>
                                                        new Promise((resolve, reject) => {
                                                            setTimeout(() => {
                                                                const dataDelete = [...tableData];
                                                                const index = oldData.tableData.id;
                                                                dataDelete.splice(index, 1);
                                                                setTableData([...dataDelete]);

                                                                resolve()
                                                            }, 1000)
                                                        }),

                                                }}
                                                icons={{
                                                    Delete: () => <div className="btn-custom-editing-cart red-btn-editing-cart">
                                                        <div className="btn-custom-editing-cart-text order-status-text-status">
                                                            {t('obrisi')}
                                                        </div>
                                                    </div>
                                                }}
                                                options={{
                                                    search: false,
                                                    showTitle: false,
                                                    toolbar: false,
                                                    paging: false,
                                                    filtering: false,
                                                    draggable: false,
                                                    sorting: false,
                                                    headerStyle: {
                                                        whiteSpace: "nowrap",
                                                        fontWeight: "bold"
                                                    },
                                                    rowStyle: {
                                                        fontSize: "14px",
                                                    },
                                                    actionsColumnIndex: -1,
                                                }}
                                            />
                                            <div className="button-add-holder-pickupreturn">
                                                <div className="btn-add-article-pickupreturn" onClick={sendData}>{t('potvrdi')}</div>
                                            </div>
                                        </>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default PickReturnPage;