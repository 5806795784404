import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signin } from '../redux/actions/userActions';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

import axios from 'axios';
import { axiosinstance } from '../configAxios';
import { globalStatistic } from '../redux/actions/otherActions'
import { getPageSeo } from '../redux/actions/pageActions';

import MessageInfoBox from '../components/other/MessageInfoBox';
import PageHelmet from '../components/helmet/PageHelmet';
import { useTranslation } from 'react-i18next';

const LoginPage = (props) => {
    const recaptchaRef = React.createRef();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailForg, setEmailForg] = useState('');
    const [loginForm, setLoginForm] = useState(true);
    const [bannerList, setBannerList] = useState([]);
    const [validInput, setValidInput] = useState(0);
    const [pageSeoData, setPageSeoData] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation('loginPage');

    const userSignin = useSelector((state) => state.userInfo);

    const dispatch = useDispatch();

    const loginSubmitForm = (e) => {
        e.preventDefault();
        dispatch(signin(email, password));
    }

    const resetPasswordSubmitForm = (e) => {
        e.preventDefault();

        if (emailForg == "") {
            setValidInput(1)
        } else {
            setValidInput(2)

            try {
                const attributes = {};
                attributes['acEmail'] = emailForg;

                const configRes = axiosinstance(attributes, '8224', 'postResetForgottenPassword');

                axios(configRes)
                    .then(function (response) {
                        if (response.data.response.isOk == 1) {
                            axios({
                                method: "POST",
                                url: "https://newb2b.ewe.rs:5004/reset-password",
                                data: {
                                    token: t('url') + "zaboravljena-lozinka/" + response.data.response.acTokken,
                                    acEmail: attributes['acEmail']
                                }
                            }).then(function (response) {
                                toast(<MessageInfoBox message={response.data.acMsg} type={response.data.isOk} />, {
                                    className: response.data.isOk == 1 ? 'green-background' : 'red-background',
                                });
                            }).catch(function (error) {
                                console.log(error);
                                toast(<MessageInfoBox message="globalError" type="0" />, {
                                    className: 'red-background',
                                });
                            })
                            setValidInput(0)
                            setEmailForg('')
                        } else {
                            toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                                className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                            });
                            setValidInput(1)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        toast(<MessageInfoBox message="globalError" type="0" />, {
                            className: 'red-background',
                        });
                    });

            } catch (error) {
                console.log(error)
                toast(<MessageInfoBox message="globalError" type="0" />, {
                    className: 'red-background',
                });
            }
        }
    }

    useEffect(() => {
        dispatch(getPageSeo(354)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });
        dispatch(globalStatistic(354, 'LOGIN'));
        const attributes = {};
        attributes['anCompanyKey'] = 0;
        attributes["anUserKey"] = 0;
        const configRes = axiosinstance(attributes, '8224', 'postSetCompanyPromoMaterial');
        axios(configRes)
            .then(function (response2) {
                setBannerList(response2.data.response.banner)
            })
            .catch(function (error) {
                console.log(error, 'error');
            });

    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const bennerListItem = bannerList.filter(x => x.anPageKey == 354);

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            <div className="container-fluid">

                {/* {userSignin.isOk == 0 && <MessageInfoBox type="error" message={userSignin.message} />} */}
                <div className="row">

                    <div className="login-container-holder">
                        <div className="login-holder-block-form">
                            <div className="container-login-form">
                                {loginForm ? (
                                    <>
                                        <form onSubmit={(e) => loginSubmitForm(e)} noValidate="novalidate">
                                            <h1 title={pageSeoData.acSeoTitle ? pageSeoData.acSeoTitle : t('naslov1')}>
                                                <div className="logo-login-image">
                                                    <Link to="/">
                                                        <img src={process.env.PUBLIC_URL + '/ewe-login.png'} className="img-fluid" alt='Ewe B2B login logo' />
                                                    </Link>
                                                </div>
                                            </h1>
                                            <h2>{pageSeoData.acSeoHeader ? pageSeoData.acSeoHeader : t('naslov1')}</h2>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    onChange={(e) => setEmail(e.target.value.trim())}
                                                    value={email}
                                                    autoComplete="off"
                                                    required
                                                    placeholder={t('placeholder1')}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <div className="password-input-container">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        className="form-control"
                                                        id="exampleInputPassword1"
                                                        onChange={(e) => setPassword(e.target.value.trim())}
                                                        value={password}
                                                        autoComplete="off"
                                                        placeholder={t('placeholder2')}
                                                        required
                                                    />
                                                    <i
                                                        className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                                        onClick={togglePasswordVisibility}
                                                    ></i>
                                                </div>
                                            </div>
                                            <div className="button-login-content text-center">
                                                <button type="submit" className="btn btn-success text-center custom-button-login">{t('dugme')}</button>
                                            </div>
                                        </form>
                                        <div className={`alert alert-danger ${userSignin.isOk ? userSignin.isOk === 0 ? 'show-error-message' : '' : ''}`} role="alert">
                                            {userSignin.message}
                                        </div>
                                        <div className="link-item-text-content">
                                            <div className="link-item-text" onClick={() => setLoginForm(false)}>{t('zaboravljenaLozinka')}</div>
                                            <Link to="/registracija">
                                                <div className="link-item-text">{t('kreirajNalog')}</div>
                                            </Link>
                                            <small>{t('info')}</small>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <form onSubmit={(e) => resetPasswordSubmitForm(e)} novalidate="novalidate">
                                            <div className="logo-login-image">
                                                <img src={process.env.PUBLIC_URL + '/ewe-login.png'} className="img-fluid" alt="ewe" />
                                            </div>
                                            <h2>{t('zaboravljenaLozinka2')}</h2>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className={`form-control ${validInput == 1 ? 'is-invalid' : validInput == 2 && 'is-valid'}`}
                                                    id="exampleInputEmail12"
                                                    aria-describedby="emailHelp"
                                                    autoComplete="off"
                                                    value={emailForg}
                                                    onChange={(e) => setEmailForg(e.target.value)}
                                                    placeholder={t('placeholder3')}
                                                    required
                                                />
                                                {validInput == 1 &&
                                                    <div className="survey-invalid text-center">
                                                        <span>{t('obaveznoPolje')}</span>
                                                    </div>
                                                }
                                            </div>
                                            <div className="button-login-content text-center">
                                                <button type="submit" className="btn btn-success text-center custom-button-login">{t('dugme2')}</button>
                                            </div>
                                        </form>
                                        <div className="link-item-text-content">
                                            <div className="link-item-text" onClick={() => setLoginForm(true)}><i className="fas fa-long-arrow-alt-left"></i>&nbsp; {t('dugme3')}</div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="login-holder-block-image">
                            {bennerListItem.length == 0 ? (
                                ''
                            ) : (
                                    bennerListItem[0]?.acBannerImage
                                        ? <img className="img-fluid" alt="" src={"https://resource.ewe.rs/media/" + bennerListItem[0].acBannerImage} />
                                        : <img className="img-fluid" alt="" src={"https://resource.ewe.rs/media/banners/2022/11/hisense-login.png"} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default LoginPage;