// import 'bootstrap/dist/css/bootstrap.min.css';
import captchaImg from '../../img/captcha.jpg';
import studentIMG from '../../img/2.png';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getLoyaltyProducts, getLoyaltySettings } from '../../redux/actions/productActions';
import { createLoyaltyOrder } from '../../redux/actions/loyaltyActions';
import { axiosinstance } from '../../configAxios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import MessageInfoBox from '../other/MessageInfoBox';
import SecureLS from 'secure-ls';
import { Modal, Button } from 'react-bootstrap';

const Captcha = ({ setHideModal, handleClose, values, setFinishCartFunction, finishCart, setEmptyValues, type, start, end, socketResponse }) => {

  const { t } = useTranslation('other', 'cartPage');
  const userInfoData = useSelector((state) => state.userInfo.userInfo);
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    username: ""
  });
  const [delivery, setDelivery] = useState('I');
  // const [captcha, setCaptcha] = useState('I');
  let ls = new SecureLS({ encodingType: 'aes' });
  const [showFailModal, setShowFailModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);


  const handleCloseFailModal = () => setShowFailModal(false);
  const handleCloseSuccessModal = () => setShowConfirmModal(false);

  const handleConfirm = () => {
    // Logika za potvrdu

    setShowFailModal(false);
    window.location.reload();

  };
  const handleConfirmSuccess = () => {
    // Logika za potvrdu kada je kupovina uspesna

    setShowConfirmModal(false);
    dispatch(getLoyaltyProducts(2));
    dispatch(getLoyaltySettings(2));
    document.getElementById("form-loyalty-shop").reset();
    setEmptyValues([]);
    window.location.reload();
    handleClose();

  };
  // // const { t } = useTranslation('cartPage');

  // const characters = 'qwertyuopasdfghjkzxcvbnm1234567890';
  // // var captcha = '';
  // useEffect(() => {

  //   generateString(6) // Function called here and save in captcha variable
  // }, [])

  // function generateString(length) {
  //   let result = '';
  //   const charactersLength = characters.length;
  //   for (let i = 0; i < length; i++) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }
  //   setCaptcha(result);
  //   //  return result;
  // }

  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    user[name] = value;
    setUser(user);
  }
  const inputFeilds = document.querySelectorAll(".loyalty-input-value");
  const validInputs = Array.from(inputFeilds).filter(input => input.value !== "");
  let items = [];

  validInputs.forEach(element => {
    let item = {
      anQty: element.value,
      acProductName: element.getAttribute('data-product-name'),
      acProduct: element.getAttribute('data-product-ident'),
      anPrice: element.getAttribute('data-product-price'),
      anProductId: element.getAttribute('data-product-id'),
      // anPriceTotal: element.value * element.getAttribute('data-product-price')
    };
    items.push(item);
  });
  let totalPrice = 0;
  totalPrice = items.reduce((total, currentItem) => {
    return total + (parseFloat(currentItem.anPrice) * currentItem.anQty);
  }, 0);
  totalPrice = totalPrice.toFixed(2);
  totalPrice = totalPrice.replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  // console.log(items, 'items');
  // console.log(totalPrice, "totPrice");

  // const onSubmit = e => {

  //   var element = document.getElementById("succesBTN");
  //   var inputData = document.getElementById("inputType");
  //   // element.style.cursor = "wait";
  //   // element.innerHTML = "Provera...";
  //   // inputData.disabled = true;
  //   // element.disabled = true;
  //   var myFunctions = function () {
  //     // if (captcha == user.username) {
  //     //   element.style.backgroundColor = "green";
  //     //   element.innerHTML = "Uspešna kupovina";
  //     //   element.disabled = true;
  //     //   element.style.cursor = "not-allowed";
  //     //   inputData.style.display = "none";
  //     // }

  //     // Podaci o kupovini
  //     const inputFeilds = document.querySelectorAll(".loyalty-input-value");
  //     const validInputs = Array.from(inputFeilds).filter(input => input.value !== "");

  //     var objArr = [];
  //     var objArrMail = [];
  //     validInputs.forEach(element => {
  //       var obj = {};
  //       var objMail = {};
  //       obj['anQty'] = element.value;//
  //       obj['anProductKey'] = element.getAttribute('data-product');//
  //       obj['anProductId'] = element.getAttribute('data-product-id');//
  //       objArr.push(obj);

  //       objMail['anQty'] = element.value;//
  //       objMail['anProductKey'] = element.getAttribute('data-product');//
  //       objMail['acProductName'] = element.getAttribute('data-product-name');
  //       objMail['anPrice'] = element.getAttribute('data-product-price');
  //       objMail['acProduct'] = element.getAttribute('data-product-ident');
  //       objMail['anPriceTotal'] = element.value * element.getAttribute('data-product-price');
  //       objArrMail.push(objMail);
  //     });

  //     var totalSum = 0;
  //     objArrMail.forEach(element => {
  //       totalSum += element.anPriceTotal;
  //     });

  //     //formatiranje brojeva za mail
  //     objArrMail.map(item => {
  //       item.anPrice = Math.round(item.anPrice * 100) / 100
  //       item.anPriceTotal = Math.round(item.anPriceTotal * 100) / 100
  //     })

  //     totalSum = Math.round(totalSum * 100) / 100

  //     // var userInfoData = ls.get('userInfo');
  //     const attr = {};

  //     console.log("Korisnički podaci:", userInfoData);
  //     attr['anUserId'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
  //     const axiosConfig = axiosinstance(attr, '8224', 'getCurrentTime');

  //     axios(axiosConfig)
  //       .then(function (response) {
  //         console.log("Trenutno vreme sa servera:", response.data.response.adTime);
  //         let trenutniDatum = new Date(response.data.response.adTime);
  //         let krajnjiDatum = new Date(end);
  //         let pocetniDatum = new Date(start);

  //         if (trenutniDatum < pocetniDatum || trenutniDatum > krajnjiDatum) {
  //           toast(<MessageInfoBox message={'Dogodila se greška'} type={0} />, {
  //             className: 'red-background'
  //           });
  //         } else {
  //           const attributes = {};
  //           attributes['anUserKey'] = userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0;
  //           attributes["anCompanyKey"] = userInfoData ? userInfoData.company.anCompanyKey : 0;
  //           attributes["acDelivery"] = delivery;
  //           attributes['acLoyalityTable'] = objArr;
  //           attributes['anLoyaltyTypeId'] = type;
  //           console.log("Podaci za završetak korpe:", attributes, objArrMail);

  //           const configRes = axiosinstance(attributes, "8225", "postLoyalityFinishCart");

  //           axios(configRes)
  //             .then(function (response) {
  //               console.log(response, "ress");
  //               console.log("Odgovor na završetak korpe:", response.data);
  //               if (response.data.response.isOk == 1) {

  //                 setFinishCartFunction(!finishCart);

  //                 console.log("Podaci za slanje email-a:", {
  //                   objArrMail: objArrMail,
  //                   totalSum: totalSum,
  //                   delivery: delivery,
  //                   userInfoData: userInfoData,
  //                   cartId: response.data.response.b2bCart._id,
  //                   type: type
  //                 });

  //                 axios({
  //                   method: "POST",
  //                   url: "https://newb2b.ewe.rs:5004/send-mail-loyalty",
  //                   data: {
  //                     objArrMail: objArrMail,
  //                     totalSum: totalSum,
  //                     delivery: delivery,
  //                     userInfoData: userInfoData,
  //                     cartId: response.data.response.b2bCart._id,
  //                     type: type
  //                   }
  //                 }).then((response) => {
  //                   if (response.data.msg === 'success') {
  //                     alert("Email sent, awesome!");
  //                   } else if (response.data.msg === 'fail') {
  //                     alert("Oops, something went wrong. Try again");
  //                   }
  //                 }).catch((error) => {
  //                   console.error("Greška prilikom slanja email-a:", error);
  //                 });

  //                 dispatch({
  //                   type: 'SET_MESSAGE_INFO',
  //                   payload: response.data
  //                 });

  //                 toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
  //                   className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
  //                 });

  //                 dispatch(getLoyaltyProducts(2));
  //                 dispatch(getLoyaltySettings(2));
  //                 document.getElementById("form-loyalty-shop").reset();
  //                 setEmptyValues([]);
  //                 window.location.reload();
  //                 handleClose();
  //               } else {
  //                 toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
  //                   className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
  //                 });

  //                 setFinishCartFunction(!finishCart);
  //                 document.getElementById("form-loyalty-shop").reset();
  //                 setEmptyValues([]);
  //                 handleClose();
  //               }
  //             })
  //             .catch(function (error) {
  //               console.error("Greška prilikom poziva `postLoyalityFinishCart`:", error);
  //             });
  //         }
  //       })
  //       .catch(function (error) {
  //         console.error("Greška prilikom poziva `getCurrentTime`:", error);
  //       });
  //   };

  //   setTimeout(myFunctions, 500);
  // };

  const onSubmit = (e) => {
    e.preventDefault(); // Sprečava podrazumevani submit form

    const element = document.getElementById("succesBTN");
    const inputData = document.getElementById("inputType");

    var myFunctions = function () {
      // Prikupljanje podataka iz unosa
      const inputFields = document.querySelectorAll(".loyalty-input-value");
      const validInputs = Array.from(inputFields).filter(input => input.value !== "");

      let objArr = [];
      let objArrMail = [];

      validInputs.forEach(element => {
        let obj = {
          anQty: element.value,
          anProductKey: element.getAttribute('data-product'),
          anProductId: element.getAttribute('data-product-id')
        };
        objArr.push(obj);

        let objMail = {
          anQty: element.value,
          anProductKey: element.getAttribute('data-product'),
          acProductName: element.getAttribute('data-product-name'),
          anPrice: parseFloat(element.getAttribute('data-product-price')),
          acProduct: element.getAttribute('data-product-ident'),
          anPriceTotal: element.value * parseFloat(element.getAttribute('data-product-price'))
        };
        objArrMail.push(objMail);
      });

      // Ukupna suma
      let totalSum = objArrMail.reduce((sum, item) => sum + item.anPriceTotal, 0);
      totalSum = Math.round(totalSum * 100) / 100;

      // Formatiranje cena za mail
      objArrMail = objArrMail.map(item => ({
        ...item,
        anPrice: Math.round(item.anPrice * 100) / 100,
        anPriceTotal: Math.round(item.anPriceTotal * 100) / 100
      }));

      const attr = { anUserId: userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0 };
      const axiosConfig = axiosinstance(attr, '8224', 'getCurrentTime');

      axios(axiosConfig)
        .then(response => {
          let trenutniDatum = new Date(response.data.response.adTime);
          let krajnjiDatum = new Date(end);
          let pocetniDatum = new Date(start);

          if (trenutniDatum < pocetniDatum || trenutniDatum > krajnjiDatum) {
            toast(<MessageInfoBox message={'Dogodila se greška'} type={0} />, {
              className: 'red-background'
            });
          } else {
            const attributes = {
              anUserKey: userInfoData ? userInfoData.company.department[0].user[0].anUserKey : 0,
              anCompanyKey: userInfoData ? userInfoData.company.anCompanyKey : 0,
              acDelivery: delivery,
              acLoyalityTable: objArr,
              anLoyaltyTypeId: type
            };

            const configRes = axiosinstance(attributes, "8225", "postLoyalityFinishCart");

            axios(configRes)
              .then(response => {
                // console.log(response, 'res');
                if (response.data.response.isOk == 1) {
                  setFinishCartFunction(!finishCart);

                  axios({
                    method: "POST",
                    url: "https://newb2b.ewe.rs:5000/send-mail-loyalty",
                    data: {
                      objArrMail: objArrMail,
                      totalSum: totalSum,
                      delivery: delivery,
                      userInfoData: userInfoData,
                      cartId: response.data.response.b2bCart._id,
                      type: type
                    }
                  }).then(response => {
                    if (response.data.acMsg === 'success') {
                      // dispatch(getLoyaltyProducts(2));
                      // dispatch(getLoyaltySettings(2));
                      // document.getElementById("form-loyalty-shop").reset();
                      // setEmptyValues([]);
                      // window.location.reload();
                      // handleClose();
                      setHideModal(true); setShowConfirmModal(true);
                    } else if (response.data.acMsg === 'fail') {
                      setHideModal(true); setShowFailModal(true);
                    }
                  }).catch(error => {
                    console.error("Greška prilikom slanja email-a:", error);
                  });

                  toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: response.data.response.isOk == 1 ? 'green-background' : 'red-background',
                  });

                  // dispatch(getLoyaltyProducts(2));
                  // dispatch(getLoyaltySettings(2));
                  // document.getElementById("form-loyalty-shop").reset();
                  // setEmptyValues([]);
                  // // window.location.reload();
                  // handleClose();
                } else {
                  toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                    className: 'red-background',
                  });
                }
              })
              .catch(error => {
                console.error("Greška prilikom poziva `postLoyalityFinishCart`:", error);
              });
          }
        })
        .catch(error => {
          console.error("Greška prilikom poziva `getCurrentTime`:", error);
        });
    };

    setTimeout(myFunctions, 1000);
  };

  var element = document.getElementById("succesBTN");
  var inputData = document.getElementById("inputType");
  
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="main-cart-items-table-custom">
              <table className="table main-cart-table table-striped table-hover">
                <thead>
                  <tr>
                    <th className="width-150-td" style={{ border: "none", padding: ".5rem" }}>Sifra</th>
                    <th className="" style={{ border: "none", padding: ".5rem" }}>Naziv</th>
                    <th className="width-150-td" style={{ border: "none", padding: ".5rem" }}>Cena</th>
                    <th className="width-150-td" style={{ border: "none", padding: ".5rem" }}>Količina</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.acProduct}</td>
                      <td>{item.acProductName}</td>
                      <td>{item.anPrice} EUR</td>
                      <td style={{ textAlign: "center" }}>{item.anQty}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='holder-summary' style={{ display: "flex", marginTop: "30px", marginBottom: "30px", justifyContent: "space-between" }}>
              <div className='col-sm-5'>
                <div className="form-group  loyalty-captcha-image-code" style={{ display: "flex", alignItems: "center" }}>
                  <label className="mr-3" style={{ textWrap: "nowrap" }}>{t('nacinDostave')}</label>
                  <select name="" className="form-control" onChange={(e) => setDelivery(e.target.value)}>
                    <option value="I">{t('isporukaNaAdrKupca')}</option>
                    <option value="Z">{t('licnoUzemunu')}</option>
                    <option value="L">{t('licnoUlestanima')}</option>
                  </select>
                </div>
              </div>
              <div className='col-sm-4'></div>
              <div className='col-sm-3' style={{ display: "flex" }}>
                <label className='span-info-cart-box'>UKUPNO :</label>
                <span><b>{totalPrice} EUR</b></span>
              </div>
            </div>
            <div className="form-group row">
              <div className='col-sm-10'></div>
              <button type="button" id="succesBTN" onClick={onSubmit} className="btn btn-success col-sm-2">{t('potvrdiKupovinu')}</button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showFailModal}
        onHide={handleConfirm}
        keyboard={false}
        dialogClassName='modal-xl'
        aria-labelledby=""
        centered
      >
        <div className="close-action-modal" variant="secondary" onClick={handleConfirm}>
          <i className="far fa-times-circle"></i>
        </div>
        <Modal.Header>
          <h4>Info</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-primary" role="alert">
            Vaša porudžbina je uspešna, ali postoji problem sa slanjem potvrdnog maila.
            Za više detalja, molimo kontaktirajte komercijalistu.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleConfirm}>
            Zatvori
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmModal}
        onHide={handleConfirmSuccess}
        keyboard={false}
        dialogClassName='modal-xl'
        aria-labelledby=""
        centered
      >
        <div className="close-action-modal" variant="secondary" onClick={handleConfirmSuccess}>
          <i className="far fa-times-circle"></i>
        </div>
        <Modal.Header>
          <h4>Info</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-primary" role="alert">
            Vaša porudžbina je uspešna, uskoro će vam stići mail o potvrdi porudžbine.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleConfirmSuccess}>
            Zatvori
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Captcha;