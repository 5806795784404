import React, { useEffect, useState } from 'react';
import PageBanner from '../components/banner/PageBanner';
import PageHelmet from '../components/helmet/PageHelmet';
import { useDispatch } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';

const DellPage = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('dellPage');

    useEffect(() => {
        dispatch(globalStatistic(761, 'PAGE'));
        dispatch(getPageSeo(761)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        })
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={`${pageSeoData.acSeoTitle}`}
                    description={`${pageSeoData.acSeoDescription}`}
                    keywords={`${pageSeoData.acSeoKeywords}`}
                    ogDescription={`${pageSeoData.acSeoDescription}`}
                    ogTitle={`${pageSeoData.acSeoTitle}`}
                    ogUrl={`https://ewe.rs/${pageSeoData.acSeoUrl}`}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="article"
                    twitterDescription={`${pageSeoData.acSeoDescription}`}
                />
            }
            <div className="container-content container-catalog-content delivery-form-page">
                <div className="container container-dell-page">
                    <div className="row">
                        <div className="col-sm-12 container-dell-page-col-12">
                            <div className="content-main-center" style={{ backgroundImage: 'url(/media/pozadina-dell.png)' }}>
                                <div className="image-block-dell-page">
                                    <img src="/media/Dell-5-godina-LP-novi-logo.png" alt="" className="img-fluid" />
                                </div>
                                <div className="items-dell-block-content">
                                    <div className="item-dell-block">
                                        <div className="item-dell-block-number">1</div>
                                        <div className="item-dell-block-main-content">
                                            <div className="item-dell-block-main-title">{t('5GodGar')}</div>
                                            <div className="item-dell-block-main-desc">
                                                <p>{t('uSlucajuKvara')}</p><br />
                                                <p>{t('uslovGarancije')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-dell-block">
                                        <div className="item-dell-block-number">2</div>
                                        <div className="item-dell-block-main-content">
                                            <div className="item-dell-block-main-title">{t('5godBesplatnogOdr')}</div>
                                            <div className="item-dell-block-main-desc">
                                                <p>{t('kakoBiSeGarantovao')}.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-dell-block">
                                        <div className="item-dell-block-number">3</div>
                                        <div className="item-dell-block-main-content">
                                            <div className="item-dell-block-main-title">{t('spasavanjePodataka')}</div>
                                            <div className="item-dell-block-main-desc">
                                                <p>{t('pasus1')}</p><br />
                                                <p>{t('usluga')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-dell-block">
                                        <div className="item-dell-block-number">4</div>
                                        <div className="item-dell-block-main-content">
                                            <div className="item-dell-block-main-title">{t('daljinskaPodrka')}</div>
                                            <div className="item-dell-block-main-desc">
                                                <p>{t('satVrOnLine')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-dell-block">
                                        <div className="item-dell-block-number">5</div>
                                        <div className="item-dell-block-main-content">
                                            <div className="item-dell-block-main-title">{t('iskustvoSaDell')}</div>
                                            <div className="item-dell-block-main-desc">
                                                <p>{t('uveriteSe')}</p>
                                            </div>
                                        </div><br />
                                        <p style={{ marginLeft: '50px' }}>{t('latitude')}</p>
                                    </div>
                                </div>
                                <div className="image-block-dell-page">
                                    <img src="/media/5-godina-donji-deo-dell.png" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DellPage;